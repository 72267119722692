/* ========================================================
	Liberty
	Version:   1.0
	Author:    Kel Andersen
	Date:      2017-04-05
	Last Mod:  2017-06-15 by Kel Andersen

	Note: lib styles includes

==========================================================*/
/* init paths */
/* initial */
/* ========================================================
	Liberty - Variables
  Version:   2.0
	Author:    Kel Andersen
  Date:      2017-04-05
	Last Mod:  2017-04-05 by Kel Andersen

	Note: common variables

==========================================================*/
/* --------------------------------------------------------
	Paths
----------------------------------------------------------*/
/* --------------------------------------------------------
	Default Size
----------------------------------------------------------*/
/* ========================================================
	Liberty - MixIns
  Version:   2.0
	Author:    Kel Andersen
  Date:      2017-04-05
	Last Mod:  2017-04-05 by Kel Andersen

	Note: common mixins for project use

==========================================================*/
/* --------------------------------------------------------
	FOR Loops
----------------------------------------------------------*/
/* numbers */
/* arrays */
/* --------------------------------------------------------
	Fonts / Line Height
----------------------------------------------------------*/
/* --------------------------------------------------------
	Content
----------------------------------------------------------*/
/* --------------------------------------------------------
	Alignment
----------------------------------------------------------*/
/* --------------------------------------------------------
	Backgrounds
----------------------------------------------------------*/
/* --------------------------------------------------------
	Shadows
----------------------------------------------------------*/
/* --------------------------------------------------------
	Borders
----------------------------------------------------------*/
/* --------------------------------------------------------
	Opacity
----------------------------------------------------------*/
/* --------------------------------------------------------
	Gradients
----------------------------------------------------------*/
/* --------------------------------------------------------
	Animation
----------------------------------------------------------*/
/* ========================================================
	Liberty - Colours
	Version:   2.0
	Author:    Kel Andersen
  Date:      2017-04-05
	Last Mod:  2017-04-05 by Kel Andersen

	Note: standard colours

==========================================================*/
/* --------------------------------------------------------
	Corporate Colours
----------------------------------------------------------*/
/* --------------------------------------------------------
	Semantic
----------------------------------------------------------*/
/* --------------------------------------------------------
	Standard Palette
----------------------------------------------------------*/
/* --------------------------------------------------------
	Messages
----------------------------------------------------------*/
/* utilities */
/* ========================================================
	Liberty - Animate
	Version:   2.0
	Author:    Kel Andersen
  Date:      2017-04-05
	Last Mod:  2017-05-08 by Kel Andersen

	Note: animations;
				- fade in/out
				- fade + scale
				- spin
				- blink
				- shake
				- ring
				- bounce
				- pulse
				- burst

==========================================================*/
/* --------------------------------------------------------
	Fade In
----------------------------------------------------------*/
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* --------------------------------------------------------
	Fade Out
----------------------------------------------------------*/
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* --------------------------------------------------------
	Fade In / Out with Scale
----------------------------------------------------------*/
/* fade in */
@-webkit-keyframes fadeInScale {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes fadeInScale {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
/* fade out */
@-webkit-keyframes fadeOutScale {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes fadeOutScale {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
}
/* --------------------------------------------------------
	Spin
----------------------------------------------------------*/
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* --------------------------------------------------------
	Blink
----------------------------------------------------------*/
@-webkit-keyframes blink {
  0%,
  100%,
  50% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink {
  0%,
  100%,
  50% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
/* --------------------------------------------------------
	Shake (normal)
----------------------------------------------------------*/
@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  8% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  10% {
    -webkit-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  18% {
    -webkit-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  20% {
    -webkit-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  28% {
    -webkit-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  30% {
    -webkit-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  38% {
    -webkit-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  40% {
    -webkit-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  48% {
    -webkit-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  50% {
    -webkit-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  58% {
    -webkit-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  60% {
    -webkit-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  68% {
    -webkit-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  75%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes shake {
  0% {
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  8% {
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  10% {
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  18% {
    -webkit-transform: rotate(-24deg);
    -ms-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  20% {
    -webkit-transform: rotate(-24deg);
    -ms-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  28% {
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  30% {
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  38% {
    -webkit-transform: rotate(-24deg);
    -ms-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  40% {
    -webkit-transform: rotate(-24deg);
    -ms-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  48% {
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  50% {
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  58% {
    -webkit-transform: rotate(-24deg);
    -ms-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  60% {
    -webkit-transform: rotate(-24deg);
    -ms-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  68% {
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  75%,
  100% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
/* --------------------------------------------------------
	Ring (fast shake)
----------------------------------------------------------*/
@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  2% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  4% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  6% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  8% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  10% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }
  12% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  14% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  18% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  20%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  2% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  4% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  6% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  8% {
    -webkit-transform: rotate(-22deg);
    -ms-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  10% {
    -webkit-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    transform: rotate(22deg);
  }
  12% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  14% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  18% {
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  20%,
  100% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
/* --------------------------------------------------------
	Bounce
----------------------------------------------------------*/
@-webkit-keyframes bounce {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes bounce {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
/* --------------------------------------------------------
	Pulse
----------------------------------------------------------*/
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}
/* --------------------------------------------------------
	Burst
----------------------------------------------------------*/
@-webkit-keyframes burst {
  0% {
    opacity: 0.6;
  }
  50% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes burst {
  0% {
    opacity: 0.6;
  }
  50% {
    -webkit-transform: scale(1.8);
    -ms-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/* ========================================================
	Liberty - Debug
  Version:   2.0
	Author:    Kel Andersen
  Date:      2017-04-05
	Last Mod:  2017-04-05 by Kel Andersen

	Note: debug classes

==========================================================*/
.debug {
  border: 1px solid #d9534f;
}
.debug-pane {
  width: 100%;
  padding: 1em;
  color: #b06d0f;
  background-color: #fef9f3;
  border: 1px solid #ec971f;
  border-radius: 5px;
}
/* ========================================================
	Liberty - Transforms (Effects)
  Version:   2.0
	Author:    Kel Andersen
  Date:      2017-04-05
	Last Mod:  2017-04-18 by Kel Andersen

	Note: transforms / effects

==========================================================*/
/* --------------------------------------------------------
	Variables
----------------------------------------------------------*/
/* --------------------------------------------------------
	Fade Element
----------------------------------------------------------*/
/* all */
.fx-fade {
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
/* bg + border */
.fx-fade-color {
  -webkit-transition: background-color 0.5s ease 0s, border-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s, border-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s, border-color 0.5s ease 0s;
  transition: background-color 0.5s ease 0s, border-color 0.5s ease 0s;
}
/* bg only */
.fx-fade-background {
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
  transition: background-color 0.5s ease 0s;
}
/* border only */
.fx-fade-border {
  -webkit-transition: border-color 0.5s ease 0s;
  -moz-transition: border-color 0.5s ease 0s;
  -o-transition: border-color 0.5s ease 0s;
  transition: border-color 0.5s ease 0s;
}
/* --------------------------------------------------------
	Flip Element
----------------------------------------------------------*/
/* horizontal flip */
.fx-flipped {
  -webkit-transform: -1;
  -moz-transform: -1;
  -ms-transform: -1;
  -o-transform: -1;
  transform: -1;
}
.fx-flip {
  -webkit-transition: transform 0.2s ease-out 1s;
  -moz-transition: transform 0.2s ease-out 1s;
  -o-transition: transform 0.2s ease-out 1s;
  transition: transform 0.2s ease-out 1s;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
/* ========================================================
	Liberty - Hacks
  Version:   2.0
	Author:    Kel Andersen
  Date:      2017-04-05
	Last Mod:  -

	Note:
	As is often the case, some devices/browsers need additional code to get CSS to work
	in the most desired way. These mixins are used to quickly drop in hacks for each element

	Find out more here: https://github.com/IanLunn/Hover/wiki/Hacks-Explained

==========================================================*/
/* ========================================================
	Liberty - Helpers
  Version:   2.0
	Author:    Kel Andersen
  Date:      2017-04-05
	Last Mod:  2017-04-05 by Kel Andersen

	Note: helper modifiers
        - clears
        - visibility
        - resets
        - alignment
        - floats
        - other

==========================================================*/
/* --------------------------------------------------------
	Clears
----------------------------------------------------------*/
.clear {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.keep-left {
  clear: left;
}
.keep-right {
  clear: right;
}
/* --------------------------------------------------------
	Visibility
----------------------------------------------------------*/
/* full visibility */
.is-hidden {
  display: none !important;
  visibility: hidden;
}
.is-shown {
  display: block !important;
  visibility: visible;
}
/*  part visibility - in flow still */
.is-invisible {
  visibility: hidden;
}
.is-visible {
  visibility: visible;
}
/* hide text off-screen */
.hide-text {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}
/* --------------------------------------------------------
	Resets
----------------------------------------------------------*/
/* remove list styling */
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
/* remove all borders */
.no-border {
  border: none !important;
}
/* --------------------------------------------------------
	Alignment
----------------------------------------------------------*/
.block-center {
  display: block;
  margin: 0 auto;
}
/* text alignment */
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
/* alignment - variables */
/* alignment - mixins */
/* alignment - variations */
@media only screen and (min-width: 768px) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}
@media only screen and (min-width: 992px) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}
@media only screen and (min-width: 1200px) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}
/* float alignment */
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
/* float - variables */
/* float - mixins */
/* float - variations */
@media only screen and (min-width: 768px) {
  .pull-sm-left {
    float: left !important;
  }
  .pull-sm-right {
    float: right !important;
  }
}
@media only screen and (min-width: 992px) {
  .pull-md-left {
    float: left !important;
  }
  .pull-md-right {
    float: right !important;
  }
}
@media only screen and (min-width: 1200px) {
  .pull-lg-left {
    float: left !important;
  }
  .pull-lg-right {
    float: right !important;
  }
}
/* --------------------------------------------------------
	Other
----------------------------------------------------------*/
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* clickable element */
.is-clickable {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
/* --------------------------------------------------------
   Windows Phone
----------------------------------------------------------*/
.ms-touch-manipulation {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
/* ========================================================
	Liberty - Shapes
  Version:   2.0
	Author:    Kel Andersen
  Date:      2017-04-05
	Last Mod:  2017-04-05 by Kel Andersen

	Note: drawn objects / shapes

==========================================================*/
/* --------------------------------------------------------
	General
----------------------------------------------------------*/
/* requires image to be square */
.circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.oval {
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  border-radius: 1%;
}
/* --------------------------------------------------------
	Borders
----------------------------------------------------------*/
/* ========================================================
	Liberty - Spacing
  Version:   2.0
	Author:    Kel Andersen
  Date:      2017-04-05
	Last Mod:  2017-06-13 by Kel Andersen

	Note: spacing modifiers
        - general
        - single
        - doubles
        - devices

==========================================================*/
/* --------------------------------------------------------
	General
----------------------------------------------------------*/
.no-margin {
  margin: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
/* remove gutters - cols */
.no-gutter {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
/* remove gutters - rows+cols */
.row.no-gutters {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
/* --------------------------------------------------------
	Spacing
  usage: offset-m-5, offset-mb-10
         offset-vm-5, offset-hp-10
         offset-sm-m-5, offset-lg-mb-10
----------------------------------------------------------*/
/* space - variables */
/* space - mixins */
/* spacing output - default */
@media (min-width: 0) {
  .offset-m-0 {
    margin: 0px !important;
  }
  .offset-mt-0 {
    margin-top: 0px !important;
  }
  .offset-ml-0 {
    margin-left: 0px !important;
  }
  .offset-mr-0 {
    margin-right: 0px !important;
  }
  .offset-mb-0 {
    margin-bottom: 0px !important;
  }
  .offset-p-0 {
    padding: 0px !important;
  }
  .offset-pt-0 {
    padding-top: 0px !important;
  }
  .offset-pl-0 {
    padding-left: 0px !important;
  }
  .offset-pr-0 {
    padding-right: 0px !important;
  }
  .offset-pb-0 {
    padding-bottom: 0px !important;
  }
  .offset-m-5 {
    margin: 5px !important;
  }
  .offset-mt-5 {
    margin-top: 5px !important;
  }
  .offset-ml-5 {
    margin-left: 5px !important;
  }
  .offset-mr-5 {
    margin-right: 5px !important;
  }
  .offset-mb-5 {
    margin-bottom: 5px !important;
  }
  .offset-p-5 {
    padding: 5px !important;
  }
  .offset-pt-5 {
    padding-top: 5px !important;
  }
  .offset-pl-5 {
    padding-left: 5px !important;
  }
  .offset-pr-5 {
    padding-right: 5px !important;
  }
  .offset-pb-5 {
    padding-bottom: 5px !important;
  }
  .offset-m-10 {
    margin: 10px !important;
  }
  .offset-mt-10 {
    margin-top: 10px !important;
  }
  .offset-ml-10 {
    margin-left: 10px !important;
  }
  .offset-mr-10 {
    margin-right: 10px !important;
  }
  .offset-mb-10 {
    margin-bottom: 10px !important;
  }
  .offset-p-10 {
    padding: 10px !important;
  }
  .offset-pt-10 {
    padding-top: 10px !important;
  }
  .offset-pl-10 {
    padding-left: 10px !important;
  }
  .offset-pr-10 {
    padding-right: 10px !important;
  }
  .offset-pb-10 {
    padding-bottom: 10px !important;
  }
  .offset-m-15 {
    margin: 15px !important;
  }
  .offset-mt-15 {
    margin-top: 15px !important;
  }
  .offset-ml-15 {
    margin-left: 15px !important;
  }
  .offset-mr-15 {
    margin-right: 15px !important;
  }
  .offset-mb-15 {
    margin-bottom: 15px !important;
  }
  .offset-p-15 {
    padding: 15px !important;
  }
  .offset-pt-15 {
    padding-top: 15px !important;
  }
  .offset-pl-15 {
    padding-left: 15px !important;
  }
  .offset-pr-15 {
    padding-right: 15px !important;
  }
  .offset-pb-15 {
    padding-bottom: 15px !important;
  }
  .offset-m-20 {
    margin: 20px !important;
  }
  .offset-mt-20 {
    margin-top: 20px !important;
  }
  .offset-ml-20 {
    margin-left: 20px !important;
  }
  .offset-mr-20 {
    margin-right: 20px !important;
  }
  .offset-mb-20 {
    margin-bottom: 20px !important;
  }
  .offset-p-20 {
    padding: 20px !important;
  }
  .offset-pt-20 {
    padding-top: 20px !important;
  }
  .offset-pl-20 {
    padding-left: 20px !important;
  }
  .offset-pr-20 {
    padding-right: 20px !important;
  }
  .offset-pb-20 {
    padding-bottom: 20px !important;
  }
  .offset-m-30 {
    margin: 30px !important;
  }
  .offset-mt-30 {
    margin-top: 30px !important;
  }
  .offset-ml-30 {
    margin-left: 30px !important;
  }
  .offset-mr-30 {
    margin-right: 30px !important;
  }
  .offset-mb-30 {
    margin-bottom: 30px !important;
  }
  .offset-p-30 {
    padding: 30px !important;
  }
  .offset-pt-30 {
    padding-top: 30px !important;
  }
  .offset-pl-30 {
    padding-left: 30px !important;
  }
  .offset-pr-30 {
    padding-right: 30px !important;
  }
  .offset-pb-30 {
    padding-bottom: 30px !important;
  }
  .offset-m-40 {
    margin: 40px !important;
  }
  .offset-mt-40 {
    margin-top: 40px !important;
  }
  .offset-ml-40 {
    margin-left: 40px !important;
  }
  .offset-mr-40 {
    margin-right: 40px !important;
  }
  .offset-mb-40 {
    margin-bottom: 40px !important;
  }
  .offset-p-40 {
    padding: 40px !important;
  }
  .offset-pt-40 {
    padding-top: 40px !important;
  }
  .offset-pl-40 {
    padding-left: 40px !important;
  }
  .offset-pr-40 {
    padding-right: 40px !important;
  }
  .offset-pb-40 {
    padding-bottom: 40px !important;
  }
  .offset-m-50 {
    margin: 50px !important;
  }
  .offset-mt-50 {
    margin-top: 50px !important;
  }
  .offset-ml-50 {
    margin-left: 50px !important;
  }
  .offset-mr-50 {
    margin-right: 50px !important;
  }
  .offset-mb-50 {
    margin-bottom: 50px !important;
  }
  .offset-p-50 {
    padding: 50px !important;
  }
  .offset-pt-50 {
    padding-top: 50px !important;
  }
  .offset-pl-50 {
    padding-left: 50px !important;
  }
  .offset-pr-50 {
    padding-right: 50px !important;
  }
  .offset-pb-50 {
    padding-bottom: 50px !important;
  }
  .offset-vm-0 {
    margin-top: 0px;
    margin-bottom: 0px !important;
  }
  .offset-hm-0 {
    margin-left: 0px;
    margin-right: 0px !important;
  }
  .offset-vp-0 {
    padding-top: 0px;
    padding-bottom: 0px !important;
  }
  .offset-hp-0 {
    padding-left: 0px;
    padding-right: 0px !important;
  }
  .offset-vm-5 {
    margin-top: 5px;
    margin-bottom: 5px !important;
  }
  .offset-hm-5 {
    margin-left: 5px;
    margin-right: 5px !important;
  }
  .offset-vp-5 {
    padding-top: 5px;
    padding-bottom: 5px !important;
  }
  .offset-hp-5 {
    padding-left: 5px;
    padding-right: 5px !important;
  }
  .offset-vm-10 {
    margin-top: 10px;
    margin-bottom: 10px !important;
  }
  .offset-hm-10 {
    margin-left: 10px;
    margin-right: 10px !important;
  }
  .offset-vp-10 {
    padding-top: 10px;
    padding-bottom: 10px !important;
  }
  .offset-hp-10 {
    padding-left: 10px;
    padding-right: 10px !important;
  }
  .offset-vm-15 {
    margin-top: 15px;
    margin-bottom: 15px !important;
  }
  .offset-hm-15 {
    margin-left: 15px;
    margin-right: 15px !important;
  }
  .offset-vp-15 {
    padding-top: 15px;
    padding-bottom: 15px !important;
  }
  .offset-hp-15 {
    padding-left: 15px;
    padding-right: 15px !important;
  }
  .offset-vm-20 {
    margin-top: 20px;
    margin-bottom: 20px !important;
  }
  .offset-hm-20 {
    margin-left: 20px;
    margin-right: 20px !important;
  }
  .offset-vp-20 {
    padding-top: 20px;
    padding-bottom: 20px !important;
  }
  .offset-hp-20 {
    padding-left: 20px;
    padding-right: 20px !important;
  }
  .offset-vm-30 {
    margin-top: 30px;
    margin-bottom: 30px !important;
  }
  .offset-hm-30 {
    margin-left: 30px;
    margin-right: 30px !important;
  }
  .offset-vp-30 {
    padding-top: 30px;
    padding-bottom: 30px !important;
  }
  .offset-hp-30 {
    padding-left: 30px;
    padding-right: 30px !important;
  }
  .offset-vm-40 {
    margin-top: 40px;
    margin-bottom: 40px !important;
  }
  .offset-hm-40 {
    margin-left: 40px;
    margin-right: 40px !important;
  }
  .offset-vp-40 {
    padding-top: 40px;
    padding-bottom: 40px !important;
  }
  .offset-hp-40 {
    padding-left: 40px;
    padding-right: 40px !important;
  }
  .offset-vm-50 {
    margin-top: 50px;
    margin-bottom: 50px !important;
  }
  .offset-hm-50 {
    margin-left: 50px;
    margin-right: 50px !important;
  }
  .offset-vp-50 {
    padding-top: 50px;
    padding-bottom: 50px !important;
  }
  .offset-hp-50 {
    padding-left: 50px;
    padding-right: 50px !important;
  }
}
/* spacing output - device */
@media only screen and (max-width: 767px) {
  .offset-xs-m-0 {
    margin: 0px !important;
  }
  .offset-xs-mt-0 {
    margin-top: 0px !important;
  }
  .offset-xs-ml-0 {
    margin-left: 0px !important;
  }
  .offset-xs-mr-0 {
    margin-right: 0px !important;
  }
  .offset-xs-mb-0 {
    margin-bottom: 0px !important;
  }
  .offset-xs-p-0 {
    padding: 0px !important;
  }
  .offset-xs-pt-0 {
    padding-top: 0px !important;
  }
  .offset-xs-pl-0 {
    padding-left: 0px !important;
  }
  .offset-xs-pr-0 {
    padding-right: 0px !important;
  }
  .offset-xs-pb-0 {
    padding-bottom: 0px !important;
  }
  .offset-xs-m-5 {
    margin: 5px !important;
  }
  .offset-xs-mt-5 {
    margin-top: 5px !important;
  }
  .offset-xs-ml-5 {
    margin-left: 5px !important;
  }
  .offset-xs-mr-5 {
    margin-right: 5px !important;
  }
  .offset-xs-mb-5 {
    margin-bottom: 5px !important;
  }
  .offset-xs-p-5 {
    padding: 5px !important;
  }
  .offset-xs-pt-5 {
    padding-top: 5px !important;
  }
  .offset-xs-pl-5 {
    padding-left: 5px !important;
  }
  .offset-xs-pr-5 {
    padding-right: 5px !important;
  }
  .offset-xs-pb-5 {
    padding-bottom: 5px !important;
  }
  .offset-xs-m-10 {
    margin: 10px !important;
  }
  .offset-xs-mt-10 {
    margin-top: 10px !important;
  }
  .offset-xs-ml-10 {
    margin-left: 10px !important;
  }
  .offset-xs-mr-10 {
    margin-right: 10px !important;
  }
  .offset-xs-mb-10 {
    margin-bottom: 10px !important;
  }
  .offset-xs-p-10 {
    padding: 10px !important;
  }
  .offset-xs-pt-10 {
    padding-top: 10px !important;
  }
  .offset-xs-pl-10 {
    padding-left: 10px !important;
  }
  .offset-xs-pr-10 {
    padding-right: 10px !important;
  }
  .offset-xs-pb-10 {
    padding-bottom: 10px !important;
  }
  .offset-xs-m-15 {
    margin: 15px !important;
  }
  .offset-xs-mt-15 {
    margin-top: 15px !important;
  }
  .offset-xs-ml-15 {
    margin-left: 15px !important;
  }
  .offset-xs-mr-15 {
    margin-right: 15px !important;
  }
  .offset-xs-mb-15 {
    margin-bottom: 15px !important;
  }
  .offset-xs-p-15 {
    padding: 15px !important;
  }
  .offset-xs-pt-15 {
    padding-top: 15px !important;
  }
  .offset-xs-pl-15 {
    padding-left: 15px !important;
  }
  .offset-xs-pr-15 {
    padding-right: 15px !important;
  }
  .offset-xs-pb-15 {
    padding-bottom: 15px !important;
  }
  .offset-xs-m-20 {
    margin: 20px !important;
  }
  .offset-xs-mt-20 {
    margin-top: 20px !important;
  }
  .offset-xs-ml-20 {
    margin-left: 20px !important;
  }
  .offset-xs-mr-20 {
    margin-right: 20px !important;
  }
  .offset-xs-mb-20 {
    margin-bottom: 20px !important;
  }
  .offset-xs-p-20 {
    padding: 20px !important;
  }
  .offset-xs-pt-20 {
    padding-top: 20px !important;
  }
  .offset-xs-pl-20 {
    padding-left: 20px !important;
  }
  .offset-xs-pr-20 {
    padding-right: 20px !important;
  }
  .offset-xs-pb-20 {
    padding-bottom: 20px !important;
  }
  .offset-xs-m-30 {
    margin: 30px !important;
  }
  .offset-xs-mt-30 {
    margin-top: 30px !important;
  }
  .offset-xs-ml-30 {
    margin-left: 30px !important;
  }
  .offset-xs-mr-30 {
    margin-right: 30px !important;
  }
  .offset-xs-mb-30 {
    margin-bottom: 30px !important;
  }
  .offset-xs-p-30 {
    padding: 30px !important;
  }
  .offset-xs-pt-30 {
    padding-top: 30px !important;
  }
  .offset-xs-pl-30 {
    padding-left: 30px !important;
  }
  .offset-xs-pr-30 {
    padding-right: 30px !important;
  }
  .offset-xs-pb-30 {
    padding-bottom: 30px !important;
  }
  .offset-xs-m-40 {
    margin: 40px !important;
  }
  .offset-xs-mt-40 {
    margin-top: 40px !important;
  }
  .offset-xs-ml-40 {
    margin-left: 40px !important;
  }
  .offset-xs-mr-40 {
    margin-right: 40px !important;
  }
  .offset-xs-mb-40 {
    margin-bottom: 40px !important;
  }
  .offset-xs-p-40 {
    padding: 40px !important;
  }
  .offset-xs-pt-40 {
    padding-top: 40px !important;
  }
  .offset-xs-pl-40 {
    padding-left: 40px !important;
  }
  .offset-xs-pr-40 {
    padding-right: 40px !important;
  }
  .offset-xs-pb-40 {
    padding-bottom: 40px !important;
  }
  .offset-xs-m-50 {
    margin: 50px !important;
  }
  .offset-xs-mt-50 {
    margin-top: 50px !important;
  }
  .offset-xs-ml-50 {
    margin-left: 50px !important;
  }
  .offset-xs-mr-50 {
    margin-right: 50px !important;
  }
  .offset-xs-mb-50 {
    margin-bottom: 50px !important;
  }
  .offset-xs-p-50 {
    padding: 50px !important;
  }
  .offset-xs-pt-50 {
    padding-top: 50px !important;
  }
  .offset-xs-pl-50 {
    padding-left: 50px !important;
  }
  .offset-xs-pr-50 {
    padding-right: 50px !important;
  }
  .offset-xs-pb-50 {
    padding-bottom: 50px !important;
  }
}
@media only screen and (min-width: 768px) {
  .offset-sm-m-0 {
    margin: 0px !important;
  }
  .offset-sm-mt-0 {
    margin-top: 0px !important;
  }
  .offset-sm-ml-0 {
    margin-left: 0px !important;
  }
  .offset-sm-mr-0 {
    margin-right: 0px !important;
  }
  .offset-sm-mb-0 {
    margin-bottom: 0px !important;
  }
  .offset-sm-p-0 {
    padding: 0px !important;
  }
  .offset-sm-pt-0 {
    padding-top: 0px !important;
  }
  .offset-sm-pl-0 {
    padding-left: 0px !important;
  }
  .offset-sm-pr-0 {
    padding-right: 0px !important;
  }
  .offset-sm-pb-0 {
    padding-bottom: 0px !important;
  }
  .offset-sm-m-5 {
    margin: 5px !important;
  }
  .offset-sm-mt-5 {
    margin-top: 5px !important;
  }
  .offset-sm-ml-5 {
    margin-left: 5px !important;
  }
  .offset-sm-mr-5 {
    margin-right: 5px !important;
  }
  .offset-sm-mb-5 {
    margin-bottom: 5px !important;
  }
  .offset-sm-p-5 {
    padding: 5px !important;
  }
  .offset-sm-pt-5 {
    padding-top: 5px !important;
  }
  .offset-sm-pl-5 {
    padding-left: 5px !important;
  }
  .offset-sm-pr-5 {
    padding-right: 5px !important;
  }
  .offset-sm-pb-5 {
    padding-bottom: 5px !important;
  }
  .offset-sm-m-10 {
    margin: 10px !important;
  }
  .offset-sm-mt-10 {
    margin-top: 10px !important;
  }
  .offset-sm-ml-10 {
    margin-left: 10px !important;
  }
  .offset-sm-mr-10 {
    margin-right: 10px !important;
  }
  .offset-sm-mb-10 {
    margin-bottom: 10px !important;
  }
  .offset-sm-p-10 {
    padding: 10px !important;
  }
  .offset-sm-pt-10 {
    padding-top: 10px !important;
  }
  .offset-sm-pl-10 {
    padding-left: 10px !important;
  }
  .offset-sm-pr-10 {
    padding-right: 10px !important;
  }
  .offset-sm-pb-10 {
    padding-bottom: 10px !important;
  }
  .offset-sm-m-15 {
    margin: 15px !important;
  }
  .offset-sm-mt-15 {
    margin-top: 15px !important;
  }
  .offset-sm-ml-15 {
    margin-left: 15px !important;
  }
  .offset-sm-mr-15 {
    margin-right: 15px !important;
  }
  .offset-sm-mb-15 {
    margin-bottom: 15px !important;
  }
  .offset-sm-p-15 {
    padding: 15px !important;
  }
  .offset-sm-pt-15 {
    padding-top: 15px !important;
  }
  .offset-sm-pl-15 {
    padding-left: 15px !important;
  }
  .offset-sm-pr-15 {
    padding-right: 15px !important;
  }
  .offset-sm-pb-15 {
    padding-bottom: 15px !important;
  }
  .offset-sm-m-20 {
    margin: 20px !important;
  }
  .offset-sm-mt-20 {
    margin-top: 20px !important;
  }
  .offset-sm-ml-20 {
    margin-left: 20px !important;
  }
  .offset-sm-mr-20 {
    margin-right: 20px !important;
  }
  .offset-sm-mb-20 {
    margin-bottom: 20px !important;
  }
  .offset-sm-p-20 {
    padding: 20px !important;
  }
  .offset-sm-pt-20 {
    padding-top: 20px !important;
  }
  .offset-sm-pl-20 {
    padding-left: 20px !important;
  }
  .offset-sm-pr-20 {
    padding-right: 20px !important;
  }
  .offset-sm-pb-20 {
    padding-bottom: 20px !important;
  }
  .offset-sm-m-30 {
    margin: 30px !important;
  }
  .offset-sm-mt-30 {
    margin-top: 30px !important;
  }
  .offset-sm-ml-30 {
    margin-left: 30px !important;
  }
  .offset-sm-mr-30 {
    margin-right: 30px !important;
  }
  .offset-sm-mb-30 {
    margin-bottom: 30px !important;
  }
  .offset-sm-p-30 {
    padding: 30px !important;
  }
  .offset-sm-pt-30 {
    padding-top: 30px !important;
  }
  .offset-sm-pl-30 {
    padding-left: 30px !important;
  }
  .offset-sm-pr-30 {
    padding-right: 30px !important;
  }
  .offset-sm-pb-30 {
    padding-bottom: 30px !important;
  }
  .offset-sm-m-40 {
    margin: 40px !important;
  }
  .offset-sm-mt-40 {
    margin-top: 40px !important;
  }
  .offset-sm-ml-40 {
    margin-left: 40px !important;
  }
  .offset-sm-mr-40 {
    margin-right: 40px !important;
  }
  .offset-sm-mb-40 {
    margin-bottom: 40px !important;
  }
  .offset-sm-p-40 {
    padding: 40px !important;
  }
  .offset-sm-pt-40 {
    padding-top: 40px !important;
  }
  .offset-sm-pl-40 {
    padding-left: 40px !important;
  }
  .offset-sm-pr-40 {
    padding-right: 40px !important;
  }
  .offset-sm-pb-40 {
    padding-bottom: 40px !important;
  }
  .offset-sm-m-50 {
    margin: 50px !important;
  }
  .offset-sm-mt-50 {
    margin-top: 50px !important;
  }
  .offset-sm-ml-50 {
    margin-left: 50px !important;
  }
  .offset-sm-mr-50 {
    margin-right: 50px !important;
  }
  .offset-sm-mb-50 {
    margin-bottom: 50px !important;
  }
  .offset-sm-p-50 {
    padding: 50px !important;
  }
  .offset-sm-pt-50 {
    padding-top: 50px !important;
  }
  .offset-sm-pl-50 {
    padding-left: 50px !important;
  }
  .offset-sm-pr-50 {
    padding-right: 50px !important;
  }
  .offset-sm-pb-50 {
    padding-bottom: 50px !important;
  }
}
@media only screen and (min-width: 992px) {
  .offset-md-m-0 {
    margin: 0px !important;
  }
  .offset-md-mt-0 {
    margin-top: 0px !important;
  }
  .offset-md-ml-0 {
    margin-left: 0px !important;
  }
  .offset-md-mr-0 {
    margin-right: 0px !important;
  }
  .offset-md-mb-0 {
    margin-bottom: 0px !important;
  }
  .offset-md-p-0 {
    padding: 0px !important;
  }
  .offset-md-pt-0 {
    padding-top: 0px !important;
  }
  .offset-md-pl-0 {
    padding-left: 0px !important;
  }
  .offset-md-pr-0 {
    padding-right: 0px !important;
  }
  .offset-md-pb-0 {
    padding-bottom: 0px !important;
  }
  .offset-md-m-5 {
    margin: 5px !important;
  }
  .offset-md-mt-5 {
    margin-top: 5px !important;
  }
  .offset-md-ml-5 {
    margin-left: 5px !important;
  }
  .offset-md-mr-5 {
    margin-right: 5px !important;
  }
  .offset-md-mb-5 {
    margin-bottom: 5px !important;
  }
  .offset-md-p-5 {
    padding: 5px !important;
  }
  .offset-md-pt-5 {
    padding-top: 5px !important;
  }
  .offset-md-pl-5 {
    padding-left: 5px !important;
  }
  .offset-md-pr-5 {
    padding-right: 5px !important;
  }
  .offset-md-pb-5 {
    padding-bottom: 5px !important;
  }
  .offset-md-m-10 {
    margin: 10px !important;
  }
  .offset-md-mt-10 {
    margin-top: 10px !important;
  }
  .offset-md-ml-10 {
    margin-left: 10px !important;
  }
  .offset-md-mr-10 {
    margin-right: 10px !important;
  }
  .offset-md-mb-10 {
    margin-bottom: 10px !important;
  }
  .offset-md-p-10 {
    padding: 10px !important;
  }
  .offset-md-pt-10 {
    padding-top: 10px !important;
  }
  .offset-md-pl-10 {
    padding-left: 10px !important;
  }
  .offset-md-pr-10 {
    padding-right: 10px !important;
  }
  .offset-md-pb-10 {
    padding-bottom: 10px !important;
  }
  .offset-md-m-15 {
    margin: 15px !important;
  }
  .offset-md-mt-15 {
    margin-top: 15px !important;
  }
  .offset-md-ml-15 {
    margin-left: 15px !important;
  }
  .offset-md-mr-15 {
    margin-right: 15px !important;
  }
  .offset-md-mb-15 {
    margin-bottom: 15px !important;
  }
  .offset-md-p-15 {
    padding: 15px !important;
  }
  .offset-md-pt-15 {
    padding-top: 15px !important;
  }
  .offset-md-pl-15 {
    padding-left: 15px !important;
  }
  .offset-md-pr-15 {
    padding-right: 15px !important;
  }
  .offset-md-pb-15 {
    padding-bottom: 15px !important;
  }
  .offset-md-m-20 {
    margin: 20px !important;
  }
  .offset-md-mt-20 {
    margin-top: 20px !important;
  }
  .offset-md-ml-20 {
    margin-left: 20px !important;
  }
  .offset-md-mr-20 {
    margin-right: 20px !important;
  }
  .offset-md-mb-20 {
    margin-bottom: 20px !important;
  }
  .offset-md-p-20 {
    padding: 20px !important;
  }
  .offset-md-pt-20 {
    padding-top: 20px !important;
  }
  .offset-md-pl-20 {
    padding-left: 20px !important;
  }
  .offset-md-pr-20 {
    padding-right: 20px !important;
  }
  .offset-md-pb-20 {
    padding-bottom: 20px !important;
  }
  .offset-md-m-30 {
    margin: 30px !important;
  }
  .offset-md-mt-30 {
    margin-top: 30px !important;
  }
  .offset-md-ml-30 {
    margin-left: 30px !important;
  }
  .offset-md-mr-30 {
    margin-right: 30px !important;
  }
  .offset-md-mb-30 {
    margin-bottom: 30px !important;
  }
  .offset-md-p-30 {
    padding: 30px !important;
  }
  .offset-md-pt-30 {
    padding-top: 30px !important;
  }
  .offset-md-pl-30 {
    padding-left: 30px !important;
  }
  .offset-md-pr-30 {
    padding-right: 30px !important;
  }
  .offset-md-pb-30 {
    padding-bottom: 30px !important;
  }
  .offset-md-m-40 {
    margin: 40px !important;
  }
  .offset-md-mt-40 {
    margin-top: 40px !important;
  }
  .offset-md-ml-40 {
    margin-left: 40px !important;
  }
  .offset-md-mr-40 {
    margin-right: 40px !important;
  }
  .offset-md-mb-40 {
    margin-bottom: 40px !important;
  }
  .offset-md-p-40 {
    padding: 40px !important;
  }
  .offset-md-pt-40 {
    padding-top: 40px !important;
  }
  .offset-md-pl-40 {
    padding-left: 40px !important;
  }
  .offset-md-pr-40 {
    padding-right: 40px !important;
  }
  .offset-md-pb-40 {
    padding-bottom: 40px !important;
  }
  .offset-md-m-50 {
    margin: 50px !important;
  }
  .offset-md-mt-50 {
    margin-top: 50px !important;
  }
  .offset-md-ml-50 {
    margin-left: 50px !important;
  }
  .offset-md-mr-50 {
    margin-right: 50px !important;
  }
  .offset-md-mb-50 {
    margin-bottom: 50px !important;
  }
  .offset-md-p-50 {
    padding: 50px !important;
  }
  .offset-md-pt-50 {
    padding-top: 50px !important;
  }
  .offset-md-pl-50 {
    padding-left: 50px !important;
  }
  .offset-md-pr-50 {
    padding-right: 50px !important;
  }
  .offset-md-pb-50 {
    padding-bottom: 50px !important;
  }
}
@media only screen and (min-width: 1200px) {
  .offset-lg-m-0 {
    margin: 0px !important;
  }
  .offset-lg-mt-0 {
    margin-top: 0px !important;
  }
  .offset-lg-ml-0 {
    margin-left: 0px !important;
  }
  .offset-lg-mr-0 {
    margin-right: 0px !important;
  }
  .offset-lg-mb-0 {
    margin-bottom: 0px !important;
  }
  .offset-lg-p-0 {
    padding: 0px !important;
  }
  .offset-lg-pt-0 {
    padding-top: 0px !important;
  }
  .offset-lg-pl-0 {
    padding-left: 0px !important;
  }
  .offset-lg-pr-0 {
    padding-right: 0px !important;
  }
  .offset-lg-pb-0 {
    padding-bottom: 0px !important;
  }
  .offset-lg-m-5 {
    margin: 5px !important;
  }
  .offset-lg-mt-5 {
    margin-top: 5px !important;
  }
  .offset-lg-ml-5 {
    margin-left: 5px !important;
  }
  .offset-lg-mr-5 {
    margin-right: 5px !important;
  }
  .offset-lg-mb-5 {
    margin-bottom: 5px !important;
  }
  .offset-lg-p-5 {
    padding: 5px !important;
  }
  .offset-lg-pt-5 {
    padding-top: 5px !important;
  }
  .offset-lg-pl-5 {
    padding-left: 5px !important;
  }
  .offset-lg-pr-5 {
    padding-right: 5px !important;
  }
  .offset-lg-pb-5 {
    padding-bottom: 5px !important;
  }
  .offset-lg-m-10 {
    margin: 10px !important;
  }
  .offset-lg-mt-10 {
    margin-top: 10px !important;
  }
  .offset-lg-ml-10 {
    margin-left: 10px !important;
  }
  .offset-lg-mr-10 {
    margin-right: 10px !important;
  }
  .offset-lg-mb-10 {
    margin-bottom: 10px !important;
  }
  .offset-lg-p-10 {
    padding: 10px !important;
  }
  .offset-lg-pt-10 {
    padding-top: 10px !important;
  }
  .offset-lg-pl-10 {
    padding-left: 10px !important;
  }
  .offset-lg-pr-10 {
    padding-right: 10px !important;
  }
  .offset-lg-pb-10 {
    padding-bottom: 10px !important;
  }
  .offset-lg-m-15 {
    margin: 15px !important;
  }
  .offset-lg-mt-15 {
    margin-top: 15px !important;
  }
  .offset-lg-ml-15 {
    margin-left: 15px !important;
  }
  .offset-lg-mr-15 {
    margin-right: 15px !important;
  }
  .offset-lg-mb-15 {
    margin-bottom: 15px !important;
  }
  .offset-lg-p-15 {
    padding: 15px !important;
  }
  .offset-lg-pt-15 {
    padding-top: 15px !important;
  }
  .offset-lg-pl-15 {
    padding-left: 15px !important;
  }
  .offset-lg-pr-15 {
    padding-right: 15px !important;
  }
  .offset-lg-pb-15 {
    padding-bottom: 15px !important;
  }
  .offset-lg-m-20 {
    margin: 20px !important;
  }
  .offset-lg-mt-20 {
    margin-top: 20px !important;
  }
  .offset-lg-ml-20 {
    margin-left: 20px !important;
  }
  .offset-lg-mr-20 {
    margin-right: 20px !important;
  }
  .offset-lg-mb-20 {
    margin-bottom: 20px !important;
  }
  .offset-lg-p-20 {
    padding: 20px !important;
  }
  .offset-lg-pt-20 {
    padding-top: 20px !important;
  }
  .offset-lg-pl-20 {
    padding-left: 20px !important;
  }
  .offset-lg-pr-20 {
    padding-right: 20px !important;
  }
  .offset-lg-pb-20 {
    padding-bottom: 20px !important;
  }
  .offset-lg-m-30 {
    margin: 30px !important;
  }
  .offset-lg-mt-30 {
    margin-top: 30px !important;
  }
  .offset-lg-ml-30 {
    margin-left: 30px !important;
  }
  .offset-lg-mr-30 {
    margin-right: 30px !important;
  }
  .offset-lg-mb-30 {
    margin-bottom: 30px !important;
  }
  .offset-lg-p-30 {
    padding: 30px !important;
  }
  .offset-lg-pt-30 {
    padding-top: 30px !important;
  }
  .offset-lg-pl-30 {
    padding-left: 30px !important;
  }
  .offset-lg-pr-30 {
    padding-right: 30px !important;
  }
  .offset-lg-pb-30 {
    padding-bottom: 30px !important;
  }
  .offset-lg-m-40 {
    margin: 40px !important;
  }
  .offset-lg-mt-40 {
    margin-top: 40px !important;
  }
  .offset-lg-ml-40 {
    margin-left: 40px !important;
  }
  .offset-lg-mr-40 {
    margin-right: 40px !important;
  }
  .offset-lg-mb-40 {
    margin-bottom: 40px !important;
  }
  .offset-lg-p-40 {
    padding: 40px !important;
  }
  .offset-lg-pt-40 {
    padding-top: 40px !important;
  }
  .offset-lg-pl-40 {
    padding-left: 40px !important;
  }
  .offset-lg-pr-40 {
    padding-right: 40px !important;
  }
  .offset-lg-pb-40 {
    padding-bottom: 40px !important;
  }
  .offset-lg-m-50 {
    margin: 50px !important;
  }
  .offset-lg-mt-50 {
    margin-top: 50px !important;
  }
  .offset-lg-ml-50 {
    margin-left: 50px !important;
  }
  .offset-lg-mr-50 {
    margin-right: 50px !important;
  }
  .offset-lg-mb-50 {
    margin-bottom: 50px !important;
  }
  .offset-lg-p-50 {
    padding: 50px !important;
  }
  .offset-lg-pt-50 {
    padding-top: 50px !important;
  }
  .offset-lg-pl-50 {
    padding-left: 50px !important;
  }
  .offset-lg-pr-50 {
    padding-right: 50px !important;
  }
  .offset-lg-pb-50 {
    padding-bottom: 50px !important;
  }
}
/* base */
/* ========================================================
	Liberty - Base
  Version:   2.0
	Author:    Kel Andersen
  Date:      2017-04-13
	Last Mod:

	Note: base setup

==========================================================*/
/* --------------------------------------------------------
	Base
----------------------------------------------------------*/
/* IE10 Snap Mode */
@-ms-viewport {
  width: device-width;
  height: device-height;
}
body {
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  min-width: 320px;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #0a3371;
}
/* ========================================================
	Liberty - Cursors
  Version:   2.0
	Author:    Kel Andersen
	Date:      2017-04-05
	Last Mod:  -

	Note: cursor types

==========================================================*/
/* --------------------------------------------------------
	Cursors
----------------------------------------------------------*/
.cursor-action {
  cursor: pointer;
}
.cursor-help {
  cursor: help;
}
.cursor-wait {
  cursor: wait;
}
.cursor-select {
  cursor: text;
}
.cursor-move {
  cursor: move;
}
.cursor-disabled {
  cursor: not-allowed;
}
.cursor-none {
  cursor: none;
}
/* ========================================================
	Liberty - Linkages
  Version:   2.0
	Author:    Kel Andersen
	Date:      2017-04-05
	Last Mod:  -

	Note: common links

==========================================================*/
/* --------------------------------------------------------
	Linkages
----------------------------------------------------------*/
a {
  text-decoration: none;
  outline: 0;
}
a img {
  border: 0px;
}
a:link,
a:visited {
  color: #0a3370;
}
a:hover,
a:active,
a:focus {
  color: #1053b6;
}
/* ========================================================
	Liberty - Typography
  Version:   2.0
	Author:    Kel Andersen
	Date:      2017-04-05
	Last Mod:  2017-04-05 by Kel Andersen

	Note: common typography

==========================================================*/
/* --------------------------------------------------------
	Variables
----------------------------------------------------------*/
/* --------------------------------------------------------
	Base Size
----------------------------------------------------------*/
html {
  font-size: 62.5%;
  /* set base 10px or 100%=16px */
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  color: #333;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
/* --------------------------------------------------------
	General
----------------------------------------------------------*/
p {
  margin: 0;
  padding: 0.5em 0 0.5em 0;
  color: #555;
  font-size: 1.0em;
  line-height: 1.48;
}
b,
strong {
  font-weight: bold;
}
small {
  font-size: 80%;
}
smaller {
  font-size: 60%;
}
/* --------------------------------------------------------
	Headings
----------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0.5em 0 0.5em 0;
  color: #0a3370;
  font-weight: 600;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
h1 {
  font-size: 1.4em;
  line-height: 1.8em;
}
h2 {
  font-size: 1.2em;
  line-height: 1.6em;
}
h3 {
  font-size: 1.1em;
  line-height: 1.4em;
}
h4 {
  font-size: 1.0em;
  line-height: 1.2em;
}
h5 {
  font-size: 0.9em;
  line-height: 1.1em;
}
h6 {
  font-size: 0.8em;
  line-height: 1.0em;
}
/* --------------------------------------------------------
	Superscript + Subscript
----------------------------------------------------------*/
sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 50%;
  line-height: 0;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
/* elements */
/* ========================================================
	Liberty - Images
  Version:   2.0
	Author:    Kel Andersen
	Date:      2017-04-05
	Last Mod:  -

	Note: common image rules

==========================================================*/
img {
  vertical-align: middle;
  border: 0;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
/* ========================================================
	Liberty - Icons
  Version:   2.0
	Author:    Kel Andersen
	Date:      2017-04-05
	Last Mod:  2017-04-05 by Kel Andersen

	Note: common icons for use

==========================================================*/
/* --------------------------------------------------------
	Variables
----------------------------------------------------------*/
/* --------------------------------------------------------
	Setup
	usage: icon icon-info
	usage: icon icon-left icon-info
	usage: btn > btn-label icon icon-left icon-info
----------------------------------------------------------*/
.icon {
  position: relative;
  display: inline-block;
}
.icon:before {
  padding: 12px;
  font-size: 24px;
  font-size: 2.4rem;
  font-family: FontAwesome;
}
.icon.icon-left,
.icon.icon-right {
  position: absolute;
}
.icon.icon-left {
  padding-left: 0.2em;
}
.icon.icon-left:before {
  left: 0.2em;
}
.icon.icon-right {
  padding-right: 0.2em;
}
.icon.icon-right:before {
  right: 0.2em;
}
.icon.no-padding {
  padding: 0;
}
/* --------------------------------------------------------
    Sizes
    standard: 48px, small: 24px with touch padding 12px
----------------------------------------------------------*/
.icon-xs {
  padding: 0;
  font-size: 14px;
  font-size: 1.4rem;
}
.icon-lg {
  padding: 0;
  font-size: 48px;
  font-size: 4.8rem;
}
/* ========================================================
	Liberty - Buttons
  Version:   2.0
	Author:    Kel Andersen
	Date:      2017-04-05
	Last Mod:  2017-04-18 by Kel Andersen

	Note: standard buttons

==========================================================*/
/* --------------------------------------------------------
	Variables
----------------------------------------------------------*/
/* --------------------------------------------------------
	Repeater
----------------------------------------------------------*/
/* --------------------------------------------------------
	Setup
	usage: btn btn-primary
	usage: btn btn-primary btn-block
	usage: btn btn-primary btn-link
	usage: btn btn-primary btn-outline
	usage: btn btn-primary is-raised
----------------------------------------------------------*/
.btn {
  padding: 0.5em 1em;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: normal;
  border: 1px solid transparent;
  box-shadow: none;
  /* soft edge */
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  /* disabled */
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
  cursor: pointer;
  outline: none;
}
.btn.disabled,
.btn.is-disabled,
.btn[disabled],
fieldset[disabled] .btn,
.btn.disabled:hover,
.btn.is-disabled:hover,
.btn[disabled]:hover,
fieldset[disabled] .btn:hover,
.btn.disabled:focus,
.btn.is-disabled:focus,
.btn[disabled]:focus,
fieldset[disabled] .btn:focus,
.btn.disabled:active,
.btn.is-disabled:active,
.btn[disabled]:active,
fieldset[disabled] .btn:active,
.btn.disabled.active,
.btn.is-disabled.active,
.btn[disabled].active,
fieldset[disabled] .btn.active {
  cursor: not-allowed;
}
/* --------------------------------------------------------
	Utilities
----------------------------------------------------------*/
.btn.is-rounded {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.btn.is-uppercase {
  text-transform: uppercase;
}
.btn.is-raised:not(.btn-link) {
  margin-top: 3px;
  margin-bottom: -3px;
}
/* --------------------------------------------------------
	Blocks
----------------------------------------------------------*/
.btn-block + .btn-block {
  margin-top: 0.8em;
}
/* --------------------------------------------------------
	Size
----------------------------------------------------------*/
/* Revisino Required */
/* small */
.btn-xs {
  padding: 0.5em 1em;
  font-size: 12px;
  font-size: 1.2rem;
}
/* large */
.btn-lg {
  padding: 0.8em 1em;
  font-size: 16px;
  font-size: 1.6rem;
}
/* --------------------------------------------------------
	Btn with Icon
	usage: btn > btn-label icon icon-lock
----------------------------------------------------------*/
.btn-label.icon {
  margin-right: 0.2em;
}
/* --------------------------------------------------------
	Dropdown
	usage: btn btn-dropdown
----------------------------------------------------------*/
.btn-dropdown {
  padding: 0.4em 0.5em;
  background-color: #fff;
  border: 1px solid #ddd;
}
.btn-dropdown:hover,
.btn-dropdown:focus,
.btn-dropdown:active,
.btn-dropdown.active {
  background-color: #ffffff;
}
/* --------------------------------------------------------
	Base Styles
	usage: btn btn-default
	usage: btn btn-primary
----------------------------------------------------------*/
.btn-default {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-default:hover {
  color: #888 !important;
  background-color: #d5d5d5 !important;
  border-color: #d5d5d5 !important;
}
.btn-default:focus,
.btn-default:active,
.btn-default.active {
  color: #888 !important;
  background-color: #d0d0d0 !important;
  border-color: #d0d0d0 !important;
}
.btn-default.btn-link {
  color: #ddd;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-default.btn-link:hover {
  color: #d5d5d5 !important;
}
.btn-default.btn-link:focus,
.btn-default.btn-link:active,
.btn-default.btn-link.active {
  color: #d0d0d0 !important;
}
.btn-default.btn-outline {
  color: #ddd;
  background-color: transparent;
  border-color: #ddd;
}
.btn-default.btn-outline:hover {
  color: #888;
  background-color: #ddd !important;
}
.btn-default.btn-outline:focus,
.btn-default.btn-outline:active,
.btn-default.btn-outline.active {
  color: #888;
  background-color: #d8d8d8 !important;
}
.btn-default.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #d0d0d0;
}
.btn-default.is-raised:not(.btn-link):active,
.btn-default.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #888 !important;
  background-color: #d0d0d0 !important;
  border-color: #d0d0d0 !important;
}
.btn-primary {
  color: #fff;
  background-color: #0a3370;
  border-color: #0a3370;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-primary:hover {
  color: #fff !important;
  background-color: #092d62 !important;
  border-color: #092d62 !important;
}
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  color: #fff !important;
  background-color: #082859 !important;
  border-color: #082859 !important;
}
.btn-primary.btn-link {
  color: #0a3370;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-primary.btn-link:hover {
  color: #092d62 !important;
}
.btn-primary.btn-link:focus,
.btn-primary.btn-link:active,
.btn-primary.btn-link.active {
  color: #082859 !important;
}
.btn-primary.btn-outline {
  color: #0a3370;
  background-color: transparent;
  border-color: #0a3370;
}
.btn-primary.btn-outline:hover {
  color: #fff;
  background-color: #0a3370 !important;
}
.btn-primary.btn-outline:focus,
.btn-primary.btn-outline:active,
.btn-primary.btn-outline.active {
  color: #fff;
  background-color: #092f67 !important;
}
.btn-primary.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #082859;
}
.btn-primary.is-raised:not(.btn-link):active,
.btn-primary.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #082859 !important;
  border-color: #082859 !important;
}
.btn-secondary {
  color: #fff;
  background-color: #00b4cd;
  border-color: #00b4cd;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-secondary:hover {
  color: #fff !important;
  background-color: #00a7be !important;
  border-color: #00a7be !important;
}
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
  color: #fff !important;
  background-color: #009eb4 !important;
  border-color: #009eb4 !important;
}
.btn-secondary.btn-link {
  color: #00b4cd;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-secondary.btn-link:hover {
  color: #00a7be !important;
}
.btn-secondary.btn-link:focus,
.btn-secondary.btn-link:active,
.btn-secondary.btn-link.active {
  color: #009eb4 !important;
}
.btn-secondary.btn-outline {
  color: #00b4cd;
  background-color: transparent;
  border-color: #00b4cd;
}
.btn-secondary.btn-outline:hover {
  color: #fff;
  background-color: #00b4cd !important;
}
.btn-secondary.btn-outline:focus,
.btn-secondary.btn-outline:active,
.btn-secondary.btn-outline.active {
  color: #fff;
  background-color: #00abc3 !important;
}
.btn-secondary.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #009eb4;
}
.btn-secondary.is-raised:not(.btn-link):active,
.btn-secondary.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #009eb4 !important;
  border-color: #009eb4 !important;
}
.btn-tertiary {
  color: #fff;
  background-color: #1d76a2;
  border-color: #1d76a2;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-tertiary:hover {
  color: #fff !important;
  background-color: #1b6d95 !important;
  border-color: #1b6d95 !important;
}
.btn-tertiary:focus,
.btn-tertiary:active,
.btn-tertiary.active {
  color: #fff !important;
  background-color: #19668c !important;
  border-color: #19668c !important;
}
.btn-tertiary.btn-link {
  color: #1d76a2;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-tertiary.btn-link:hover {
  color: #1b6d95 !important;
}
.btn-tertiary.btn-link:focus,
.btn-tertiary.btn-link:active,
.btn-tertiary.btn-link.active {
  color: #19668c !important;
}
.btn-tertiary.btn-outline {
  color: #1d76a2;
  background-color: transparent;
  border-color: #1d76a2;
}
.btn-tertiary.btn-outline:hover {
  color: #fff;
  background-color: #1d76a2 !important;
}
.btn-tertiary.btn-outline:focus,
.btn-tertiary.btn-outline:active,
.btn-tertiary.btn-outline.active {
  color: #fff;
  background-color: #1b7099 !important;
}
.btn-tertiary.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #19668c;
}
.btn-tertiary.is-raised:not(.btn-link):active,
.btn-tertiary.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #19668c !important;
  border-color: #19668c !important;
}
.btn-action {
  color: #fff;
  background-color: #8CC63E;
  border-color: #8CC63E;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-action:hover {
  color: #fff !important;
  background-color: #84bd38 !important;
  border-color: #84bd38 !important;
}
.btn-action:focus,
.btn-action:active,
.btn-action.active {
  color: #fff !important;
  background-color: #7fb535 !important;
  border-color: #7fb535 !important;
}
.btn-action.btn-link {
  color: #8CC63E;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-action.btn-link:hover {
  color: #84bd38 !important;
}
.btn-action.btn-link:focus,
.btn-action.btn-link:active,
.btn-action.btn-link.active {
  color: #7fb535 !important;
}
.btn-action.btn-outline {
  color: #8CC63E;
  background-color: transparent;
  border-color: #8CC63E;
}
.btn-action.btn-outline:hover {
  color: #fff;
  background-color: #8CC63E !important;
}
.btn-action.btn-outline:focus,
.btn-action.btn-outline:active,
.btn-action.btn-outline.active {
  color: #fff;
  background-color: #87c139 !important;
}
.btn-action.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #7fb535;
}
.btn-action.is-raised:not(.btn-link):active,
.btn-action.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #7fb535 !important;
  border-color: #7fb535 !important;
}
.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-info:hover {
  color: #fff !important;
  background-color: #4ebbdb !important;
  border-color: #4ebbdb !important;
}
.btn-info:focus,
.btn-info:active,
.btn-info.active {
  color: #fff !important;
  background-color: #46b8da !important;
  border-color: #46b8da !important;
}
.btn-info.btn-link {
  color: #5bc0de;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-info.btn-link:hover {
  color: #4ebbdb !important;
}
.btn-info.btn-link:focus,
.btn-info.btn-link:active,
.btn-info.btn-link.active {
  color: #46b8da !important;
}
.btn-info.btn-outline {
  color: #5bc0de;
  background-color: transparent;
  border-color: #5bc0de;
}
.btn-info.btn-outline:hover {
  color: #fff;
  background-color: #5bc0de !important;
}
.btn-info.btn-outline:focus,
.btn-info.btn-outline:active,
.btn-info.btn-outline.active {
  color: #fff;
  background-color: #53bddc !important;
}
.btn-info.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #46b8da;
}
.btn-info.is-raised:not(.btn-link):active,
.btn-info.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #46b8da !important;
  border-color: #46b8da !important;
}
/* --------------------------------------------------------
	Alternate Styles
----------------------------------------------------------*/
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-success:hover {
  color: #fff !important;
  background-color: #51b351 !important;
  border-color: #51b351 !important;
}
.btn-success:focus,
.btn-success:active,
.btn-success.active {
  color: #fff !important;
  background-color: #4cae4c !important;
  border-color: #4cae4c !important;
}
.btn-success.btn-link {
  color: #5cb85c;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-success.btn-link:hover {
  color: #51b351 !important;
}
.btn-success.btn-link:focus,
.btn-success.btn-link:active,
.btn-success.btn-link.active {
  color: #4cae4c !important;
}
.btn-success.btn-outline {
  color: #5cb85c;
  background-color: transparent;
  border-color: #5cb85c;
}
.btn-success.btn-outline:hover {
  color: #fff;
  background-color: #5cb85c !important;
}
.btn-success.btn-outline:focus,
.btn-success.btn-outline:active,
.btn-success.btn-outline.active {
  color: #fff;
  background-color: #55b555 !important;
}
.btn-success.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #4cae4c;
}
.btn-success.is-raised:not(.btn-link):active,
.btn-success.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #4cae4c !important;
  border-color: #4cae4c !important;
}
.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-warning:hover {
  color: #fff !important;
  background-color: #efa640 !important;
  border-color: #efa640 !important;
}
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active {
  color: #fff !important;
  background-color: #eea236 !important;
  border-color: #eea236 !important;
}
.btn-warning.btn-link {
  color: #f0ad4e;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-warning.btn-link:hover {
  color: #efa640 !important;
}
.btn-warning.btn-link:focus,
.btn-warning.btn-link:active,
.btn-warning.btn-link.active {
  color: #eea236 !important;
}
.btn-warning.btn-outline {
  color: #f0ad4e;
  background-color: transparent;
  border-color: #f0ad4e;
}
.btn-warning.btn-outline:hover {
  color: #fff;
  background-color: #f0ad4e !important;
}
.btn-warning.btn-outline:focus,
.btn-warning.btn-outline:active,
.btn-warning.btn-outline.active {
  color: #fff;
  background-color: #efa945 !important;
}
.btn-warning.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #eea236;
}
.btn-warning.is-raised:not(.btn-link):active,
.btn-warning.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #eea236 !important;
  border-color: #eea236 !important;
}
.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-danger:hover {
  color: #fff !important;
  background-color: #d64742 !important;
  border-color: #d64742 !important;
}
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active {
  color: #fff !important;
  background-color: #d43f3a !important;
  border-color: #d43f3a !important;
}
.btn-danger.btn-link {
  color: #d9534f;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-danger.btn-link:hover {
  color: #d64742 !important;
}
.btn-danger.btn-link:focus,
.btn-danger.btn-link:active,
.btn-danger.btn-link.active {
  color: #d43f3a !important;
}
.btn-danger.btn-outline {
  color: #d9534f;
  background-color: transparent;
  border-color: #d9534f;
}
.btn-danger.btn-outline:hover {
  color: #fff;
  background-color: #d9534f !important;
}
.btn-danger.btn-outline:focus,
.btn-danger.btn-outline:active,
.btn-danger.btn-outline.active {
  color: #fff;
  background-color: #d74b47 !important;
}
.btn-danger.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #d43f3a;
}
.btn-danger.is-raised:not(.btn-link):active,
.btn-danger.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #d43f3a !important;
  border-color: #d43f3a !important;
}
/* --------------------------------------------------------
	Social
	usage: btn btn-social btn-facebook > btn-icon
	usage: btn btn-social btn-facebook > btn-label
----------------------------------------------------------*/
/* social colours */
/* setup */
.btn-social {
  /* icon only */
  /* label with icon */
  /* social types */
}
.btn-social .btn-icon {
  text-align: center;
}
.btn-social .btn-icon:before {
  font-family: FontAwesome;
}
.btn-social .btn-label:before {
  width: 20px;
  margin-right: 8px;
  font-family: FontAwesome;
}
.btn-social.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-social.btn-facebook .btn-icon:before,
.btn-social.btn-facebook .btn-label:before {
  content: "\f09a";
}
.btn-social.btn-facebook:hover {
  color: #fff !important;
  background-color: #37538d !important;
  border-color: #37538d !important;
}
.btn-social.btn-facebook:focus,
.btn-social.btn-facebook:active,
.btn-social.btn-facebook.active {
  color: #fff !important;
  background-color: #344e86 !important;
  border-color: #344e86 !important;
}
.btn-social.btn-facebook.btn-link {
  color: #3b5998;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-social.btn-facebook.btn-link:hover {
  color: #37538d !important;
}
.btn-social.btn-facebook.btn-link:focus,
.btn-social.btn-facebook.btn-link:active,
.btn-social.btn-facebook.btn-link.active {
  color: #344e86 !important;
}
.btn-social.btn-facebook.btn-outline {
  color: #3b5998;
  background-color: transparent;
  border-color: #3b5998;
}
.btn-social.btn-facebook.btn-outline:hover {
  color: #fff;
  background-color: #3b5998 !important;
}
.btn-social.btn-facebook.btn-outline:focus,
.btn-social.btn-facebook.btn-outline:active,
.btn-social.btn-facebook.btn-outline.active {
  color: #fff;
  background-color: #385591 !important;
}
.btn-social.btn-facebook.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #344e86;
}
.btn-social.btn-facebook.is-raised:not(.btn-link):active,
.btn-social.btn-facebook.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #344e86 !important;
  border-color: #344e86 !important;
}
.btn-social.btn-linkedin {
  color: #fff;
  background-color: #007bb6;
  border-color: #007bb6;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-social.btn-linkedin .btn-icon:before,
.btn-social.btn-linkedin .btn-label:before {
  content: "\f0e1";
}
.btn-social.btn-linkedin:hover {
  color: #fff !important;
  background-color: #0071a7 !important;
  border-color: #0071a7 !important;
}
.btn-social.btn-linkedin:focus,
.btn-social.btn-linkedin:active,
.btn-social.btn-linkedin.active {
  color: #fff !important;
  background-color: #006a9d !important;
  border-color: #006a9d !important;
}
.btn-social.btn-linkedin.btn-link {
  color: #007bb6;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-social.btn-linkedin.btn-link:hover {
  color: #0071a7 !important;
}
.btn-social.btn-linkedin.btn-link:focus,
.btn-social.btn-linkedin.btn-link:active,
.btn-social.btn-linkedin.btn-link.active {
  color: #006a9d !important;
}
.btn-social.btn-linkedin.btn-outline {
  color: #007bb6;
  background-color: transparent;
  border-color: #007bb6;
}
.btn-social.btn-linkedin.btn-outline:hover {
  color: #fff;
  background-color: #007bb6 !important;
}
.btn-social.btn-linkedin.btn-outline:focus,
.btn-social.btn-linkedin.btn-outline:active,
.btn-social.btn-linkedin.btn-outline.active {
  color: #fff;
  background-color: #0074ac !important;
}
.btn-social.btn-linkedin.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #006a9d;
}
.btn-social.btn-linkedin.is-raised:not(.btn-link):active,
.btn-social.btn-linkedin.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #006a9d !important;
  border-color: #006a9d !important;
}
.btn-social.btn-google {
  color: #fff;
  background-color: #df4a32;
  border-color: #df4a32;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-social.btn-google .btn-icon:before,
.btn-social.btn-google .btn-label:before {
  content: "\f1a0";
}
.btn-social.btn-google:hover {
  color: #fff !important;
  background-color: #dd3e25 !important;
  border-color: #dd3e25 !important;
}
.btn-social.btn-google:focus,
.btn-social.btn-google:active,
.btn-social.btn-google.active {
  color: #fff !important;
  background-color: #d63a21 !important;
  border-color: #d63a21 !important;
}
.btn-social.btn-google.btn-link {
  color: #df4a32;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-social.btn-google.btn-link:hover {
  color: #dd3e25 !important;
}
.btn-social.btn-google.btn-link:focus,
.btn-social.btn-google.btn-link:active,
.btn-social.btn-google.btn-link.active {
  color: #d63a21 !important;
}
.btn-social.btn-google.btn-outline {
  color: #df4a32;
  background-color: transparent;
  border-color: #df4a32;
}
.btn-social.btn-google.btn-outline:hover {
  color: #fff;
  background-color: #df4a32 !important;
}
.btn-social.btn-google.btn-outline:focus,
.btn-social.btn-google.btn-outline:active,
.btn-social.btn-google.btn-outline.active {
  color: #fff;
  background-color: #de4229 !important;
}
.btn-social.btn-google.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #d63a21;
}
.btn-social.btn-google.is-raised:not(.btn-link):active,
.btn-social.btn-google.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #d63a21 !important;
  border-color: #d63a21 !important;
}
.btn-social.btn-twitter {
  color: #fff;
  background-color: #00b6f1;
  border-color: #00b6f1;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-social.btn-twitter .btn-icon:before,
.btn-social.btn-twitter .btn-label:before {
  content: "\f099";
}
.btn-social.btn-twitter:hover {
  color: #fff !important;
  background-color: #00aae2 !important;
  border-color: #00aae2 !important;
}
.btn-social.btn-twitter:focus,
.btn-social.btn-twitter:active,
.btn-social.btn-twitter.active {
  color: #fff !important;
  background-color: #00a3d8 !important;
  border-color: #00a3d8 !important;
}
.btn-social.btn-twitter.btn-link {
  color: #00b6f1;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-social.btn-twitter.btn-link:hover {
  color: #00aae2 !important;
}
.btn-social.btn-twitter.btn-link:focus,
.btn-social.btn-twitter.btn-link:active,
.btn-social.btn-twitter.btn-link.active {
  color: #00a3d8 !important;
}
.btn-social.btn-twitter.btn-outline {
  color: #00b6f1;
  background-color: transparent;
  border-color: #00b6f1;
}
.btn-social.btn-twitter.btn-outline:hover {
  color: #fff;
  background-color: #00b6f1 !important;
}
.btn-social.btn-twitter.btn-outline:focus,
.btn-social.btn-twitter.btn-outline:active,
.btn-social.btn-twitter.btn-outline.active {
  color: #fff;
  background-color: #00aee7 !important;
}
.btn-social.btn-twitter.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #00a3d8;
}
.btn-social.btn-twitter.is-raised:not(.btn-link):active,
.btn-social.btn-twitter.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #00a3d8 !important;
  border-color: #00a3d8 !important;
}
.btn-social.btn-pinterest {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-social.btn-pinterest .btn-icon:before,
.btn-social.btn-pinterest .btn-label:before {
  content: "\f0d2";
}
.btn-social.btn-pinterest:hover {
  color: #fff !important;
  background-color: #be1e24 !important;
  border-color: #be1e24 !important;
}
.btn-social.btn-pinterest:focus,
.btn-social.btn-pinterest:active,
.btn-social.btn-pinterest.active {
  color: #fff !important;
  background-color: #b51d23 !important;
  border-color: #b51d23 !important;
}
.btn-social.btn-pinterest.btn-link {
  color: #cb2027;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-social.btn-pinterest.btn-link:hover {
  color: #be1e24 !important;
}
.btn-social.btn-pinterest.btn-link:focus,
.btn-social.btn-pinterest.btn-link:active,
.btn-social.btn-pinterest.btn-link.active {
  color: #b51d23 !important;
}
.btn-social.btn-pinterest.btn-outline {
  color: #cb2027;
  background-color: transparent;
  border-color: #cb2027;
}
.btn-social.btn-pinterest.btn-outline:hover {
  color: #fff;
  background-color: #cb2027 !important;
}
.btn-social.btn-pinterest.btn-outline:focus,
.btn-social.btn-pinterest.btn-outline:active,
.btn-social.btn-pinterest.btn-outline.active {
  color: #fff;
  background-color: #c21f25 !important;
}
.btn-social.btn-pinterest.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #b51d23;
}
.btn-social.btn-pinterest.is-raised:not(.btn-link):active,
.btn-social.btn-pinterest.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #b51d23 !important;
  border-color: #b51d23 !important;
}
.btn-social.btn-dropbox {
  color: #fff;
  background-color: #007ee5;
  border-color: #007ee5;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-social.btn-dropbox .btn-icon:before,
.btn-social.btn-dropbox .btn-label:before {
  content: "\f16b";
}
.btn-social.btn-dropbox:hover {
  color: #fff !important;
  background-color: #0076d6 !important;
  border-color: #0076d6 !important;
}
.btn-social.btn-dropbox:focus,
.btn-social.btn-dropbox:active,
.btn-social.btn-dropbox.active {
  color: #fff !important;
  background-color: #0070cc !important;
  border-color: #0070cc !important;
}
.btn-social.btn-dropbox.btn-link {
  color: #007ee5;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-social.btn-dropbox.btn-link:hover {
  color: #0076d6 !important;
}
.btn-social.btn-dropbox.btn-link:focus,
.btn-social.btn-dropbox.btn-link:active,
.btn-social.btn-dropbox.btn-link.active {
  color: #0070cc !important;
}
.btn-social.btn-dropbox.btn-outline {
  color: #007ee5;
  background-color: transparent;
  border-color: #007ee5;
}
.btn-social.btn-dropbox.btn-outline:hover {
  color: #fff;
  background-color: #007ee5 !important;
}
.btn-social.btn-dropbox.btn-outline:focus,
.btn-social.btn-dropbox.btn-outline:active,
.btn-social.btn-dropbox.btn-outline.active {
  color: #fff;
  background-color: #0078db !important;
}
.btn-social.btn-dropbox.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #0070cc;
}
.btn-social.btn-dropbox.is-raised:not(.btn-link):active,
.btn-social.btn-dropbox.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #0070cc !important;
  border-color: #0070cc !important;
}
.btn-social.btn-skype {
  color: #fff;
  background-color: #00aff0;
  border-color: #00aff0;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-social.btn-skype .btn-icon:before,
.btn-social.btn-skype .btn-label:before {
  content: "\f17e";
}
.btn-social.btn-skype:hover {
  color: #fff !important;
  background-color: #00a4e1 !important;
  border-color: #00a4e1 !important;
}
.btn-social.btn-skype:focus,
.btn-social.btn-skype:active,
.btn-social.btn-skype.active {
  color: #fff !important;
  background-color: #009cd7 !important;
  border-color: #009cd7 !important;
}
.btn-social.btn-skype.btn-link {
  color: #00aff0;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-social.btn-skype.btn-link:hover {
  color: #00a4e1 !important;
}
.btn-social.btn-skype.btn-link:focus,
.btn-social.btn-skype.btn-link:active,
.btn-social.btn-skype.btn-link.active {
  color: #009cd7 !important;
}
.btn-social.btn-skype.btn-outline {
  color: #00aff0;
  background-color: transparent;
  border-color: #00aff0;
}
.btn-social.btn-skype.btn-outline:hover {
  color: #fff;
  background-color: #00aff0 !important;
}
.btn-social.btn-skype.btn-outline:focus,
.btn-social.btn-skype.btn-outline:active,
.btn-social.btn-skype.btn-outline.active {
  color: #fff;
  background-color: #00a8e6 !important;
}
.btn-social.btn-skype.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #009cd7;
}
.btn-social.btn-skype.is-raised:not(.btn-link):active,
.btn-social.btn-skype.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #009cd7 !important;
  border-color: #009cd7 !important;
}
.btn-social.btn-youtube {
  color: #fff;
  background-color: #b31217;
  border-color: #b31217;
  /* link */
  /* outline */
  /* raised ex. btn-links */
}
.btn-social.btn-youtube .btn-icon:before,
.btn-social.btn-youtube .btn-label:before {
  content: "\f16a";
}
.btn-social.btn-youtube:hover {
  color: #fff !important;
  background-color: #a51115 !important;
  border-color: #a51115 !important;
}
.btn-social.btn-youtube:focus,
.btn-social.btn-youtube:active,
.btn-social.btn-youtube.active {
  color: #fff !important;
  background-color: #9c1014 !important;
  border-color: #9c1014 !important;
}
.btn-social.btn-youtube.btn-link {
  color: #b31217;
  background-color: transparent !important;
  border: 0 transparent none !important;
}
.btn-social.btn-youtube.btn-link:hover {
  color: #a51115 !important;
}
.btn-social.btn-youtube.btn-link:focus,
.btn-social.btn-youtube.btn-link:active,
.btn-social.btn-youtube.btn-link.active {
  color: #9c1014 !important;
}
.btn-social.btn-youtube.btn-outline {
  color: #b31217;
  background-color: transparent;
  border-color: #b31217;
}
.btn-social.btn-youtube.btn-outline:hover {
  color: #fff;
  background-color: #b31217 !important;
}
.btn-social.btn-youtube.btn-outline:focus,
.btn-social.btn-youtube.btn-outline:active,
.btn-social.btn-youtube.btn-outline.active {
  color: #fff;
  background-color: #aa1116 !important;
}
.btn-social.btn-youtube.is-raised:not(.btn-link) {
  box-shadow: 0 3px 0 0 #9c1014;
}
.btn-social.btn-youtube.is-raised:not(.btn-link):active,
.btn-social.btn-youtube.is-raised:not(.btn-link).active {
  box-shadow: none;
  color: #fff !important;
  background-color: #9c1014 !important;
  border-color: #9c1014 !important;
}
/* ========================================================
	Liberty - Loaders
  Version:   1.0
	Author:    Kel Andersen
	Date:      2017-05-09
	Last Mod:  -

	Note: Loaders
        - spinner
        - pulse
        - dots

  Acknow: based on Alan Shortis pure CSS loaders

==========================================================*/
/* --------------------------------------------------------
	Variables
----------------------------------------------------------*/
/* setup */
/* size multipliers */
/* spinner */
/* dots */
/* blink */
/* --------------------------------------------------------
  Setup
  usage: loader
  usage: loader loader-inline
  usage: loader loader-entire
----------------------------------------------------------*/
.loader {
  z-index: 1001;
  position: absolute;
  top: 50%;
  left: 50%;
}
/* --------------------------------------------------------
  Button Loader
  usage: btn > loader btn-label
  usage: btn loading > loader btn-label
----------------------------------------------------------*/
.btn .loader {
  display: none;
}
.btn.loading .loader {
  display: block;
}
.btn.loading .btn-label {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}
/* --------------------------------------------------------
  Loader / Spinner
  usage: loader loader-spinner
  usage: loader loader-spinner loader-md
----------------------------------------------------------*/
/* repeater */
/* setup */
.loader-spinner {
  width: 2.2rem;
  height: 2.2rem;
  margin: -1.1rem 0 0 -1.1rem;
  border: 0.25rem solid rgba(0, 0, 0, 0.035);
  border-top-color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: spin 800ms infinite linear;
  -moz-animation: spin 800ms infinite linear;
  -ms-animation: spin 800ms infinite linear;
  -o-animation: spin 800ms infinite linear;
  animation: spin 800ms infinite linear;
  /* sizes */
}
.loader-spinner.loader-sm {
  width: 4.4rem;
  height: 4.4rem;
  margin: -2.2rem 0 0 -2.2rem;
  border-width: 0.29rem;
}
.loader-spinner.loader-md {
  width: 6.6rem;
  height: 6.6rem;
  margin: -3.3rem 0 0 -3.3rem;
  border-width: 0.32rem;
}
.loader-spinner.loader-lg {
  width: 8.8rem;
  height: 8.8rem;
  margin: -4.4rem 0 0 -4.4rem;
  border-width: 0.36rem;
}
/* --------------------------------------------------------
  Loader / Dots
  usage: loader loader-dots
----------------------------------------------------------*/
/* setup */
.loader-dots {
  width: 0.6rem;
  height: 0.6rem;
  margin: -0.3rem 0 0 -0.3rem;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-animation: glow 700ms 0.1s infinite;
  -moz-animation: glow 700ms 0.1s infinite;
  -ms-animation: glow 700ms 0.1s infinite;
  -o-animation: glow 700ms 0.1s infinite;
  animation: glow 700ms 0.1s infinite;
  /* outer dots */
}
.loader-dots:before,
.loader-dots:after {
  content: '';
  position: absolute;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.loader-dots:before {
  left: -0.9rem;
  -webkit-animation: glow 700ms 0s infinite;
  -moz-animation: glow 700ms 0s infinite;
  -ms-animation: glow 700ms 0s infinite;
  -o-animation: glow 700ms 0s infinite;
  animation: glow 700ms 0s infinite;
}
.loader-dots:after {
  left: 0.9rem;
  -webkit-animation: glow 700ms 0.2s infinite;
  -moz-animation: glow 700ms 0.2s infinite;
  -ms-animation: glow 700ms 0.2s infinite;
  -o-animation: glow 700ms 0.2s infinite;
  animation: glow 700ms 0.2s infinite;
}
/* --------------------------------------------------------
  Loader / Blink Bars
  usage: loader loader-blink
----------------------------------------------------------*/
/* setup */
.loader-blink {
  width: 0.35rem;
  height: 1.6rem;
  margin: -0.8rem 0 0 -0.175rem;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-animation: glow 700ms 0.1s infinite;
  -moz-animation: glow 700ms 0.1s infinite;
  -ms-animation: glow 700ms 0.1s infinite;
  -o-animation: glow 700ms 0.1s infinite;
  animation: glow 700ms 0.1s infinite;
  /* outer blink */
}
.loader-blink:before,
.loader-blink:after {
  content: '';
  position: absolute;
  display: block;
  width: 0.35rem;
  height: 1.6rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.loader-blink:before {
  left: -0.65rem;
  -webkit-animation: glow 700ms 0s infinite;
  -moz-animation: glow 700ms 0s infinite;
  -ms-animation: glow 700ms 0s infinite;
  -o-animation: glow 700ms 0s infinite;
  animation: glow 700ms 0s infinite;
}
.loader-blink:after {
  left: 0.65rem;
  -webkit-animation: glow 700ms 0.2s infinite;
  -moz-animation: glow 700ms 0.2s infinite;
  -ms-animation: glow 700ms 0.2s infinite;
  -o-animation: glow 700ms 0.2s infinite;
  animation: glow 700ms 0.2s infinite;
}
/* --------------------------------------------------------
  Custom Animation
----------------------------------------------------------*/
/* glow */
@-webkit-keyframes glow {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: transparent;
  }
}
@keyframes glow {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: transparent;
  }
}
/* ========================================================
	Liberty - Labels
  Version:   2.0
	Author:    Kel Andersen
	Date:      2017-04-05
	Last Mod:  -

	Note: customs labels

==========================================================*/
/* --------------------------------------------------------
	Variables
----------------------------------------------------------*/
/* --------------------------------------------------------
	Repeater
----------------------------------------------------------*/
/* --------------------------------------------------------
	Setup
----------------------------------------------------------*/
.label {
  vertical-align: middle;
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
/* --------------------------------------------------------
	Base Styles
----------------------------------------------------------*/
.label-default {
  color: #fff;
  background-color: #ddd;
  border: 2px solid #ddd;
}
.label-default.label-outline {
  color: #ddd;
  background-color: #fff;
}
.label-primary {
  color: #fff;
  background-color: #0a3370;
  border: 2px solid #0a3370;
}
.label-primary.label-outline {
  color: #0a3370;
  background-color: #fff;
}
.label-secondary {
  color: #fff;
  background-color: #00b4cd;
  border: 2px solid #00b4cd;
}
.label-secondary.label-outline {
  color: #00b4cd;
  background-color: #fff;
}
.label-action {
  color: #fff;
  background-color: #8CC63E;
  border: 2px solid #8CC63E;
}
.label-action.label-outline {
  color: #8CC63E;
  background-color: #fff;
}
.label-info {
  color: #fff;
  background-color: #5bc0de;
  border: 2px solid #5bc0de;
}
.label-info.label-outline {
  color: #5bc0de;
  background-color: #fff;
}
/* --------------------------------------------------------
	Alternate Styles
----------------------------------------------------------*/
.label-success {
  color: #fff;
  background-color: #5cb85c;
  border: 2px solid #5cb85c;
}
.label-success.label-outline {
  color: #5cb85c;
  background-color: #fff;
}
.label-warning {
  color: #fff;
  background-color: #f0ad4e;
  border: 2px solid #f0ad4e;
}
.label-warning.label-outline {
  color: #f0ad4e;
  background-color: #fff;
}
.label-danger {
  color: #fff;
  background-color: #d9534f;
  border: 2px solid #d9534f;
}
.label-danger.label-outline {
  color: #d9534f;
  background-color: #fff;
}
/* ========================================================
	Liberty - Forms
	Version: 	2.0
	Author: 	Kel Andersen
	Date:		  2015-09-28
	Last Mod: 2017-07-25 by Kel Andersen

	Note: form elements

==========================================================*/
/* --------------------------------------------------------
	Variables
----------------------------------------------------------*/
/* color - active */
/* color - disabled */
/* --------------------------------------------------------
	Setup
	usage: form-group > control-label + form-control + help-block
----------------------------------------------------------*/
.form-group {
  margin-bottom: 1em;
  /* font size */
  /* labels */
  /* elements - input, select, textarea */
  /* remove iOS inner shadow */
}
.form-group .control-label,
.form-group .form-control,
.form-group .form-control-static {
  font-size: 14px;
  font-size: 1.4rem;
}
.form-group .control-label {
  color: #555;
  font-weight: normal;
}
.form-group .form-control {
  height: 34px;
  padding: 6px 12px;
  color: #555;
  background-color: #ffffff;
  border: 1px solid #cddde6;
  /* soft edge */
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  /* remove inner shadow */
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  /* txt - first uppercase */
}
.form-group .form-control:focus {
  background-color: #ffffff;
  border: 1px solid #9ec5dc;
  -webkit-box-shadow: 0 0 6px rgba(132, 202, 242, 0.5);
  -moz-box-shadow: 0 0 6px rgba(132, 202, 242, 0.5);
  box-shadow: 0 0 6px rgba(132, 202, 242, 0.5);
}
.form-group .form-control.text-capitalise {
  text-transform: capitalize;
}
.form-group input:not([type="checkbox"]),
.form-group input:not([type="radio"]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/* --------------------------------------------------------
	Groups
----------------------------------------------------------*/
.form-group-relative {
  position: relative;
}
/* --------------------------------------------------------
	Sizing
	default height: 34px
	usage: form-group form-group-sm
	usage: form-group form-group-lg
----------------------------------------------------------*/
/* small */
.form-group-sm {
  /* font size */
}
.form-group-sm .control-label,
.form-group-sm .form-control,
.form-group-sm .form-control-static {
  font-size: 12px;
  font-size: 1.2rem;
}
.form-group-sm .form-control,
.form-group-sm .form-control-static {
  height: 30px;
  padding: 5px 10px;
  line-height: 1.5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
/* large */
.form-group-lg {
  /* font size */
}
.form-group-lg .control-label,
.form-group-lg .form-control,
.form-group-lg .form-control-static {
  font-size: 18px;
  font-size: 1.8rem;
}
.form-group-lg .form-control,
.form-group-lg .form-control-static {
  height: 46px;
  padding: 10px 16px;
  line-height: 1.3333333;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
/* --------------------------------------------------------
	Required Markers
  usage: control-label required-marker
  usage: control-label required-text
  usage: form-control required-marker
----------------------------------------------------------*/
/* on labels */
.control-label {
  /* required marker */
  /* required text */
}
.control-label.required-marker::after {
  content: "\f069";
  margin-left: 0.4em;
  vertical-align: top;
  color: #d9534f;
  font-size: 0.6em;
  font-family: FontAwesome;
}
.control-label.required-text::after {
  content: "required";
  float: right;
  margin: 0.25em;
  font-size: 0.8em;
  font-weight: normal;
  color: #d9534f;
}
/* --------------------------------------------------------
	Text Hints
  usage: form group > control-label, control-text, form-control
----------------------------------------------------------*/
.control-text {
  display: inline-block;
  font-size: 0.8em;
  font-weight: normal;
  color: #bbb;
  /* inline */
  /* linkages */
}
.control-text.inline {
  float: right;
  margin: 0.25em;
}
.control-text.below {
  max-width: 100%;
  margin-bottom: 5px;
}
.control-text > a {
  color: #0a3370;
  text-decoration: underline;
}
/* --------------------------------------------------------
	Tooltip
  usage: form group > control-label, control-tooltip, form-control
----------------------------------------------------------*/
/* inline with label */
.control-tooltip {
  display: inline-block;
  margin-left: 5px;
  font-size: 1.1em;
  line-height: 20px;
  color: #ddd;
}
/* --------------------------------------------------------
	Input Addons
	usage: input-group-addon > icon icon-calendar
	usage: input-group-addon > icon icon-cents
----------------------------------------------------------*/
.input-group-addon {
  color: #888;
  border: 1px solid #cddde6;
  /* custom icon */
  /* left side */
  /* right side */
}
.input-group-addon .icon.icon-cents:before {
  content: ".00";
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
.input-group-addon:first-child {
  border-right: 0;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 2px;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.input-group-addon:last-child {
  border-left: 0;
  -webkit-border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 2px;
  -moz-border-radius-bottomright: 2px;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
/* --------------------------------------------------------
	Select
	usage: form-group-select > select form-control
----------------------------------------------------------*/
.form-group-select {
  /* custom arrow */
}
.form-group-select select.form-control {
  padding-right: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* items */
}
.form-group-select select.form-control::-ms-expand {
  display: none;
}
.form-group-select select.form-control option {
  padding-left: 0;
}
.form-group-select::after {
  content: "\f078";
  position: absolute;
  top: 7px;
  right: 25px;
  color: #888;
  font-family: FontAwesome;
  pointer-events: none;
}
/* --------------------------------------------------------
	textarea
	usage: textarea no-resize
	usage: textarea no-vertical
----------------------------------------------------------*/
textarea {
  /* height unrestricted */
  height: auto !important;
  /* disable resizing */
  /* vertical = no */
  /* horizontal = no */
}
textarea.no-resize {
  resize: none;
}
textarea.no-vertical {
  resize: horizontal;
}
textarea.no-horizontal {
  resize: vertical;
}
/* --------------------------------------------------------
	Checkbox
	usage: checkbox checkbox-action
	usage: checkbox checkbox-circle checkbox-action
	usage: checkbox checkbox-circle checkbox-action checkbox-inline
	usage: checkbox checkbox-action radio-inline
----------------------------------------------------------*/
.checkbox {
  padding-left: 20px;
  /* note: allow radios to behave like checkbox too */
  /* checkbox - circle */
  /* checkbox - inline */
  /* grouped checkbox */
}
.checkbox label {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}
.checkbox label::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cddde6;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: #fff;
}
.checkbox label::after {
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  color: #555;
  font-size: 11px;
}
.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  /* remove defaults */
  /* outline */
  /* checked state */
  /* disabled state */
}
.checkbox input[type="checkbox"].form-control,
.checkbox input[type="radio"].form-control {
  width: 0;
  height: 0;
  padding: 0;
  border-style: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.checkbox input[type="checkbox"]:focus + label::before,
.checkbox input[type="radio"]:focus + label::before {
  -webkit-box-shadow: 0 0 6px rgba(100, 100, 100, 0.2);
  -moz-box-shadow: 0 0 6px rgba(100, 100, 100, 0.2);
  box-shadow: 0 0 6px rgba(100, 100, 100, 0.2);
}
.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
  content: "\f00c";
  font-family: FontAwesome;
}
.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  cursor: not-allowed;
}
.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
  color: #bbb;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
}
.checkbox.checkbox-circle label::before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.checkbox.checkbox-inline,
.checkbox.radio-inline {
  margin-top: 0;
}
.checkbox + .checkbox {
  margin-top: -5px;
}
/* repeater  */
/* colour variations  */
.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #0a3370;
  border-color: #0a3370;
}
.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff;
}
.checkbox-secondary input[type="checkbox"]:checked + label::before,
.checkbox-secondary input[type="radio"]:checked + label::before {
  background-color: #00b4cd;
  border-color: #00b4cd;
}
.checkbox-secondary input[type="checkbox"]:checked + label::after,
.checkbox-secondary input[type="radio"]:checked + label::after {
  color: #fff;
}
.checkbox-action input[type="checkbox"]:checked + label::before,
.checkbox-action input[type="radio"]:checked + label::before {
  background-color: #8CC63E;
  border-color: #8CC63E;
}
.checkbox-action input[type="checkbox"]:checked + label::after,
.checkbox-action input[type="radio"]:checked + label::after {
  color: #fff;
}
.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
  color: #fff;
}
/* --------------------------------------------------------
	Radio
	usage: radio radio-action
	usage: radio radio-action radio-primary
	usage: radio radio-action radio-inline
----------------------------------------------------------*/
.radio {
  padding-left: 20px;
  /* radio - inline */
  /* grouped radios */
}
.radio label {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}
.radio label::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cddde6;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
}
.radio label::after {
  content: " ";
  position: absolute;
  display: inline-block;
  width: 11px;
  height: 11px;
  top: 3px;
  left: 3px;
  margin-left: -20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #bbb;
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
}
.radio input[type="radio"] {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  /* remove defaults */
  /* outline */
  /* checked state */
  /* disabled state */
}
.radio input[type="radio"].form-control {
  width: 0;
  height: 0;
  padding: 0;
  border-style: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.radio input[type="radio"]:focus + label::before {
  -webkit-box-shadow: 0 0 6px rgba(100, 100, 100, 0.2);
  -moz-box-shadow: 0 0 6px rgba(100, 100, 100, 0.2);
  box-shadow: 0 0 6px rgba(100, 100, 100, 0.2);
}
.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio input[type="radio"]:disabled + label {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  cursor: not-allowed;
}
.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
  color: #bbb;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
}
.radio.radio-inline {
  margin-top: 0;
}
.radio + .radio {
  margin-top: -5px;
}
/* repeater  */
/* colour variations  */
.radio-primary input[type="radio"] + label::after {
  background-color: #0a3370;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #0a3370;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #0a3370;
}
.radio-secondary input[type="radio"] + label::after {
  background-color: #00b4cd;
}
.radio-secondary input[type="radio"]:checked + label::before {
  border-color: #00b4cd;
}
.radio-secondary input[type="radio"]:checked + label::after {
  background-color: #00b4cd;
}
.radio-action input[type="radio"] + label::after {
  background-color: #8CC63E;
}
.radio-action input[type="radio"]:checked + label::before {
  border-color: #8CC63E;
}
.radio-action input[type="radio"]:checked + label::after {
  background-color: #8CC63E;
}
.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de;
}
/* --------------------------------------------------------
	Disabled States
----------------------------------------------------------*/
/* repeater */
.form-control.disabled,
.form-control.is-disabled,
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  cursor: not-allowed;
  color: #bbb;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
}
/* --------------------------------------------------------
	Validation / Help Blocks
	usage: help-block icon
----------------------------------------------------------*/
/* setup */
.help-block {
  display: none;
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  color: #bbb;
  font-size: 0.9em;
}
/* show message */
.has-warning .help-block,
.has-error .help-block {
  display: block;
}
/* help block repeater */
/* success */
.has-success {
  /* input */
  /* message */
  /* icon */
}
.has-success .form-control {
  background-color: #fff !important;
  border-color: #388038 !important;
}
.has-success .input-group-addon {
  color: #388038 !important;
  border-color: #388038 !important;
}
.has-success .help-block,
.has-success .form-control-feedback {
  color: #388038 !important;
}
.has-success .help-block.icon:before {
  content: "#388038, "\f00c"";
  font-family: FontAwesome;
}
/* warning */
.has-warning {
  /* input */
  /* message */
  /* icon */
}
.has-warning .form-control {
  background-color: #fff !important;
  border-color: #d18212 !important;
}
.has-warning .input-group-addon {
  color: #d18212 !important;
  border-color: #d18212 !important;
}
.has-warning .help-block,
.has-warning .form-control-feedback {
  color: #d18212 !important;
}
.has-warning .help-block.icon:before {
  content: "#d18212, "\f06a"";
  font-family: FontAwesome;
}
/* error */
.has-error {
  /* input */
  /* message */
  /* icon */
}
.has-error .form-control {
  background-color: #fff !important;
  border-color: #a82824 !important;
}
.has-error .input-group-addon {
  color: #a82824 !important;
  border-color: #a82824 !important;
}
.has-error .help-block,
.has-error .form-control-feedback {
  color: #a82824 !important;
}
.has-error .help-block.icon:before {
  content: "#a82824, "\e014"";
  font-family: FontAwesome;
}
/* ========================================================
	Liberty - Alerts
  Version:   2.0
	Author:    Kel Andersen
	Date:      2017-04-05
	Last Mod:  -

	Note: Custom Alerts

==========================================================*/
/* --------------------------------------------------------
	Variables
----------------------------------------------------------*/
/* --------------------------------------------------------
	Repeater
----------------------------------------------------------*/
/* --------------------------------------------------------
	Setup
----------------------------------------------------------*/
.alert {
  margin-bottom: 1em;
  padding: 1em 12px;
  font-size: 14px;
  font-size: 1.4rem;
  border: 0px solid transparent;
  /* reset curves */
  -webkit-border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0px;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  /* linkages */
}
.alert a {
  text-decoration: underline;
}
/* --------------------------------------------------------
	Base Styles
----------------------------------------------------------*/
.alert-default {
  color: #28a1c5;
  background-color: #ddd;
  border-color: #888;
  /* linkages */
}
.alert-default a {
  color: #28a1c5;
}
.alert-default a:hover,
.alert-default a:active {
  color: #2390b0;
}
.alert-info {
  color: #28a1c5;
  background-color: #f0f9fc;
  border-color: #31b0d5;
  /* linkages */
}
.alert-info a {
  color: #28a1c5;
}
.alert-info a:hover,
.alert-info a:active {
  color: #2390b0;
}
.alert-success {
  color: #2d672d;
  background-color: #d8eed8;
  border-color: #449d44;
  /* linkages */
}
.alert-success a {
  color: #2d672d;
}
.alert-success a:hover,
.alert-success a:active {
  color: #255625;
}
.alert-warning {
  color: #b06d0f;
  background-color: #fef9f3;
  border-color: #ec971f;
  /* linkages */
}
.alert-warning a {
  color: #b06d0f;
}
.alert-warning a:hover,
.alert-warning a:active {
  color: #985f0d;
}
.alert-danger {
  color: #8b211e;
  background-color: #f9e2e2;
  border-color: #c9302c;
  /* linkages */
}
.alert-danger a {
  color: #8b211e;
}
.alert-danger a:hover,
.alert-danger a:active {
  color: #761c19;
}
/* ========================================================
	Liberty - Drop Downs
  Version:   2.0
	Author:    Kel Andersen
	Date:      2017-04-05
	Last Mod:  -

	Note: Custom dropdowns / filters

==========================================================*/
/* --------------------------------------------------------
	Variables
----------------------------------------------------------*/
/* --------------------------------------------------------
  Setup
  usage: filter-custom filter-left
  usage: filter-custom filter-right
  usage: filter-custom filter-right dropup
  usage: filter-custom filter-left > filter-label + filter-value
----------------------------------------------------------*/
.filter-custom {
  /* positions */
}
.filter-custom.filter-left {
  float: left;
}
.filter-custom.filter-right {
  float: right;
}
.filter-custom .filter-label,
.filter-custom .filter-value {
  color: #0a3370;
  font-size: 0.85em;
  text-transform: capitalize;
}
.filter-custom .filter-label {
  margin-right: 0.5em;
}
.filter-custom .btn-dropdown .caret {
  margin-top: -4px;
  margin-left: 2px;
  width: 10px;
  height: 10px;
  color: #bbb;
  font-size: 0.7em;
  font-family: FontAwesome;
  border: none;
}
/* --------------------------------------------------------
    Customise Caret
----------------------------------------------------------*/
.filter-custom .btn-dropdown {
  /* upwards */
  /* downwards */
}
.filter-custom .btn-dropdown .dropup .caret:before {
  content: '\f077';
}
.filter-custom .btn-dropdown .dropdown .caret:before {
  content: '\f078';
}
/* --------------------------------------------------------
    Full Width
    usage: filter-custom filter-custom-block
----------------------------------------------------------*/
.filter-custom-block {
  width: 100%;
}
.filter-custom-block .btn-dropdown {
  display: block;
  width: 100%;
  height: 2.4em;
}
.filter-custom-block .btn-dropdown .filter-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 0.7em;
  left: 1em;
  right: 3em;
}
.filter-custom-block .btn-dropdown .caret {
  position: absolute;
  top: 50%;
  right: 1em;
}
.filter-custom-block .dropdown-menu {
  width: 100%;
}
.filter-custom-block .dropdown-menu > li > a {
  text-align: center;
}
/* --------------------------------------------------------
    Dropdown Options
----------------------------------------------------------*/
.dropdown-menu {
  min-width: 120px;
  padding: 0;
  font-size: 0.85em;
  border: 1px solid #ddd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  /* align right */
  /* parent override */
  /* upward override */
}
.dropdown-menu .divider {
  margin: 0;
}
.dropdown-menu > li {
  cursor: pointer;
  text-transform: capitalize;
  border-bottom: 1px solid #ddd;
}
.dropdown-menu > li:last-child {
  border-bottom: 0;
}
.dropdown-menu > li > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.6em 1em;
}
.dropdown-menu > li > a.is-highlighted {
  color: #0a3370;
  font-weight: bold !important;
}
.filter-custom.filter-right .dropdown-menu > li > a {
  padding-right: 1.8em;
  text-align: right;
}
.dropdown .dropdown-menu {
  margin-top: -2px;
}
.dropup .dropdown-menu {
  margin-bottom: -2px;
}
/* ========================================================
	Liberty - Panels
  Version:   1.0
	Author:    Kel Andersen
	Date:      2017-04-018
	Last Mod:  -

	Note: standard panels

==========================================================*/
/* --------------------------------------------------------
	Variables
----------------------------------------------------------*/
/* --------------------------------------------------------
	Repeater
----------------------------------------------------------*/
/* --------------------------------------------------------
	Setup
	usage: panel panel-default
	usage: panel panel-heading panel-body panel-footer
----------------------------------------------------------*/
.panel {
  font-size: 14px;
  font-size: 1.4rem;
  border: 0px solid transparent;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.panel .panel-heading {
  -webkit-border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topright: 0px;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0px;
}
.panel .panel-heading .panel-title {
  font-weight: normal;
}
/* --------------------------------------------------------
	Base Styles
	usage: panel panel-default
	usage: panel panel-primary
----------------------------------------------------------*/
.panel-default {
  border-color: #ddd;
}
.panel-default .panel-heading {
  color: #888;
  background-color: #ddd;
}
.panel-primary {
  border-color: #0a3370;
}
.panel-primary .panel-heading {
  color: #fff;
  background-color: #0a3370;
}
.panel-secondary {
  border-color: #00b4cd;
}
.panel-secondary .panel-heading {
  color: #fff;
  background-color: #00b4cd;
}
.panel-tertiary {
  border-color: #1d76a2;
}
.panel-tertiary .panel-heading {
  color: #fff;
  background-color: #1d76a2;
}
.panel-action {
  border-color: #8CC63E;
}
.panel-action .panel-heading {
  color: #fff;
  background-color: #8CC63E;
}
.panel-info {
  border-color: #5bc0de;
}
.panel-info .panel-heading {
  color: #fff;
  background-color: #5bc0de;
}
/* --------------------------------------------------------
	Alternate Styles
----------------------------------------------------------*/
.panel-success {
  border-color: #5cb85c;
}
.panel-success .panel-heading {
  color: #fff;
  background-color: #5cb85c;
}
.panel-warning {
  border-color: #f0ad4e;
}
.panel-warning .panel-heading {
  color: #fff;
  background-color: #f0ad4e;
}
.panel-danger {
  border-color: #d9534f;
}
.panel-danger .panel-heading {
  color: #fff;
  background-color: #d9534f;
}
/* ========================================================
	Liberty - Tooltips
  Version:   2.0
	Author:    Kel Andersen
	Date:      2017-04-05
	Last Mod:  -

	Note: Tooltips, Popovers

==========================================================*/
/* --------------------------------------------------------
    Tooltip
----------------------------------------------------------*/
/* tooltip */
.tooltip .tooltip-inner {
  padding: 0.8em 1em;
  text-align: center;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  word-wrap: break-word;
}
/* ========================================================
	Liberty - Popovers
  Version:   2.0
	Author:    Kel Andersen
	Date:      2017-04-05
	Last Mod:  -

	Note: Popovers

==========================================================*/
/* --------------------------------------------------------
    Popover
----------------------------------------------------------*/
.popover .popover-inner .popover-content {
  padding: 0.6em 0.8em;
  text-align: center;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  word-wrap: break-word;
}
/* ========================================================
	Liberty - Happy Browse
  Version:   2.0
	Author:    Kel Andersen
	Date:      2017-05-09
	Last Mod:  -

	Note: Browser + JS Prompts

==========================================================*/
/* --------------------------------------------------------
	Notify Bar Message
----------------------------------------------------------*/
.browsehappy,
.jshappy {
  z-index: 100;
  position: fixed;
  width: 100%;
  top: 0;
  margin: 0;
  padding: 1em 0;
  color: #693838;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}
.browsehappy a,
.jshappy a {
  text-decoration: none;
}
.browsehappy a:link,
.jshappy a:link,
.browsehappy a:visited,
.jshappy a:visited {
  color: #feeaea;
}
.browsehappy a:hover,
.jshappy a:hover,
.browsehappy a:active,
.jshappy a:active {
  color: #f4f4f4;
}
/* colourise */
.browsehappy {
  color: #b06d0f;
  background-color: #f2b866;
}
.jshappy {
  color: #8b211e;
  background-color: #de6764;
}
/* --------------------------------------------------------
	Full Page Message
----------------------------------------------------------*/
.detect-container {
  z-index: 2001;
  position: fixed;
  width: 100%;
  min-width: 320px;
  height: 100%;
  min-height: 320px;
  top: 0;
  left: 0;
  margin: 0 auto;
  font-size: 14px;
  font-size: 1.4rem;
  background: #fff;
  /* content area */
}
.detect-container .detect-panel {
  text-align: center;
  vertical-align: middle;
  /* hor.+vert align */
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -45%);
  -ms-transform: translate(-50%, -45%);
  transform: translate(-50%, -45%);
  /* ie8 reposition */
  /* logo */
  /* message */
}
html.ie8 .detect-container .detect-panel {
  position: relative;
  top: 30%;
  left: auto;
}
.detect-container .detect-panel .detect-logo {
  width: 160px;
  margin: 0 auto;
  /* pad if logo is present */
}
.detect-container .detect-panel .detect-logo + .detect-content {
  margin-top: 2em;
}
.detect-container .detect-panel .detect-content {
  min-width: 240px;
  max-width: 340px;
  margin: 0 auto;
  padding: 1em;
  text-align: center;
  background-color: #fbfbfb;
  border: 1px solid #dedede;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  /* title */
  /* summary */
}
.detect-container .detect-panel .detect-content .detect-content-title {
  padding-top: 0;
  color: #0a3370;
  font-size: 1.2em;
  font-weight: normal;
  line-height: 1.2em;
  text-indent: 0;
}
.detect-container .detect-panel .detect-content .detect-content-summary {
  padding: 0;
  color: #888;
  font-size: 0.9em;
  /* not first */
}
.detect-container .detect-panel .detect-content .detect-content-summary + p {
  padding-top: 0.8em;
}
/* hide app in ie8 */
html.ie8 #app {
  display: none !important;
  visibility: hidden;
}
